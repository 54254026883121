import { imageBaseURL } from '@lib/imageUtils';
import { ValuePropContent, WanderlyReason } from '../types';

export const INITIAL_VALUE_PROP_SELF: ValuePropContent = {
  title: 'You are awesome! ✨',
  subheader: 'Join thousands of families building connections through storytelling. Wanderly is child-led, open-ended, and magical.',
  // valueProps: [
  //   {
  //     text: 'Your child is the hero and the author',
  //     icon: '🦸',
  //   },
  //   {
  //     text: 'Beautiful custom illustrations',
  //     icon: '🎨',
  //   },
  //   {
  //     text: 'Print your story as a keepsake book',
  //     icon: '📖',
  //   },
  // ],
  userQuote: {
    text: 'This has been an unforgettable experience, bonding over storytelling and nurturing her confidence as a young author.',
    author: 'Cynthia D.',
  },
};

export const INITIAL_VALUE_PROP_GIFT: ValuePropContent = {
  title: 'What a thoughtful gift! 🎁',
  subheader: 'Wanderly is a gift of imagination. Create magical moments together or let them embark on their own adventure.',
  userQuote: {
    text: 'So I’m on this road trip from Little Rock to Dallas with my niece and nephew and Wanderly is the blockbuster hit of this trip. They keep asking for more.',
    author: 'Auntie Camille',
  },
};

export const REASON_VALUE_PROPS: Record<WanderlyReason, ValuePropContent> = {
  [WanderlyReason.CREATIVITY_CONFIDENCE]: {
    title: 'Watch your child\'s imagination soar with personalized adventures',
    subheader: "Watch your child's imagination soar with personalized adventures",
    imageSrc: '/path/to/creativity-image.jpg',
    userQuote: {
      text: "My son's confidence has grown so much since we started creating stories together",
      author: 'Michael R., Dad',
    },
  },
  [WanderlyReason.PERSONALIZED_STORIES]: {
    title: 'Choose their own adventure 🎒',
    subheader: 'They choose the story, the characters, and what happens next. It\'s a story only they could tell.',
    imageSrc: `${imageBaseURL}misc/checkout/make_choices_landscape_640w.webp`,
    // userQuote: {
    //   text: 'It\'s so fun and I love hearing what choice he will pick.',
    //   author: 'Eliza',
    // },
  },
  [WanderlyReason.CONNECT_WITH]: {
    title: 'Build lasting bonds with your children through shared storytelling moments',
    subheader: 'Build lasting bonds with your children through shared storytelling moments',
    imageSrc: '/path/to/connect-image.jpg',
    userQuote: {
      text: 'Story time has become our favorite way to connect at the end of each day',
      author: 'David K., Father',
    },
  },
  [WanderlyReason.CUSTOM_ILLUSTRATIONS]: {
    title: 'It\'s really me! 🦸',
    subheader: 'Dozens of avatars and thousands of parent-reviewed illustrations make each page come alive.',
    imageSrc: `${imageBaseURL}misc/checkout/showcase_custom_illustrations_landscape_640w.webp`,
    // valueProps: [
    //   {
    //     text: 'Dozens of avatars to choose from',
    //     icon: '👤',
    //   },
    //   {
    //     text: 'Thousands of parent-reviewed illustrations',
    //     icon: '✅',
    //   },
    //   {
    //     text: 'Each page comes alive as they choose what happens next',
    //     icon: '🎨',
    //   },
    // ],
  },
  [WanderlyReason.READING_PRACTICE]: {
    title: 'Built for growing readers 🎓',
    subheader: 'Wanderly nurtures a love of reading when they are young and builds confidence as they grow.',
    valueProps: [
      {
        text: 'A story for every interest',
        icon: '🌈',
      },
      {
        text: '4 reading levels grow with your child',
        icon: '📈',
      },
      {
        text: 'Choices keep them engaged',
        icon: '👀',
      },
    ],
    userQuote: {
      text: 'My dyslexic 4th grader struggles with reading and comprehension. She is finally excited to read using Wanderly!',
      author: 'Bill',
    },
  },
  [WanderlyReason.PERSONALIZED_BOOKS]: {
    title: 'Stories you can hold 📖',
    subheader: 'High-quality, hardcover books with custom illustrations, and their name on the cover.',
    imageSrc: `${imageBaseURL}misc/checkout/personalized_book_landscape_640w.webp`,
  },
};
