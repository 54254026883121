import { imageBaseURL } from '@lib/imageUtils';
import React, { useRef, useEffect, useState } from 'react';

function PolaroidStrip({ isDesktop }: { isDesktop: boolean }) {
  const containerRef = useRef<HTMLDivElement>(null);
  const imagesContainerRef = useRef<HTMLDivElement>(null);
  const [shouldCenter, setShouldCenter] = useState(false);

  useEffect(() => {
    const checkWidth = () => {
      if (containerRef.current && imagesContainerRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        const imagesWidth = imagesContainerRef.current.scrollWidth;
        setShouldCenter(containerWidth > imagesWidth);
      }
    };

    checkWidth();
    window.addEventListener('resize', checkWidth);
    return () => window.removeEventListener('resize', checkWidth);
  }, []);

  const photos = [
    {
      id: 1,
      src: `${imageBaseURL}misc/landing/indie_square_full.webp`,
      quote: '1000/1000 LOVE ❤️',
      attribution: '@sweetandsassysisterss_',
      link: 'https://www.instagram.com/reel/DElWSm7s-tv/',
      rotate: '-rotate-3',
      translateY: '-translate-y-4',
    },
    {
      id: 2,
      src: `${imageBaseURL}misc/landing/lena_square_full.webp`,
      quote: "It's my daughter's new favorite app",
      attribution: '@sweetsofties',
      link: 'https://www.instagram.com/reel/DD5PL1rz_v7/',
      rotate: 'rotate-2',
      translateY: 'translate-y-6',
    },
    {
      id: 3,
      src: `${imageBaseURL}misc/landing/zay_square_full.webp`,
      quote: 'Yeaaaaaaaah!!!',
      attribution: '@sheshe_n_zz',
      link: 'https://www.tiktok.com/@sheshe_n_zz/video/7447722703978810654',
      rotate: '-rotate-1',
      translateY: '-translate-y-2',
    },
    {
      id: 5,
      src: `${imageBaseURL}misc/landing/chloe_square_full.webp`,
      quote: "She's been reading it at least once a day since she received it",
      attribution: '@universally_cute',
      link: 'https://www.instagram.com/reel/DDfKZI0xp4f/',
      rotate: '-rotate-2',
      translateY: 'translate-y-2',
    },
    {
      id: 6,
      src: `${imageBaseURL}misc/landing/della_square_full.webp`,
      quote: 'She loved the process and reading the final product 🫶🏽',
      attribution: '@maliar18',
      link: 'https://www.instagram.com/reel/DEkonkVPcIs/',
      rotate: 'rotate-1',
      translateY: '-translate-y-6',
    },
  ];

  const hoverClasses = isDesktop
    ? 'hover:rotate-0 hover:translate-y-0 hover:scale-105 hover:shadow-xl'
    : '';

  return (
    <div ref={containerRef} className={`w-full ${isDesktop ? 'p-12' : 'p-4'} overflow-y-hidden overflow-x-auto`}>
      <div className={`flex ${shouldCenter ? 'justify-center' : ''} ${isDesktop ? 'px-8' : 'px-2'} gap-4`}>
        <div
          ref={imagesContainerRef}
          className={`flex items-center gap-8 relative h-[400px] pr-8 ${shouldCenter ? 'mx-auto' : ''}`}
        >
          {photos.map((photo) => (
            <div
              key={`${photo.src}`}
              className={`w-64 bg-white p-3 rounded-lg
                ${photo.rotate} ${photo.translateY}
                transform 
                transition-all duration-300 ease-in-out
                shadow-lg
                ${hoverClasses}`}
            >
              <div className="relative">
                <div className="mb-3">
                  <img
                    src={photo.src}
                    alt={photo.attribution}
                    className="w-full h-56 object-cover pointer-events-none"
                  />
                </div>

                <div className="text-center space-y-2">
                  <p className="text-gray-700 text-sm italic">
                    &quot;
                    {photo.quote}
                    &quot;
                  </p>
                  <a
                    href={photo.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-primary hover:text-primary-dark text-sm font-medium cursor-pointer"
                    onClick={(e) => e.stopPropagation()}
                  >
                    {photo.attribution}
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default PolaroidStrip;
