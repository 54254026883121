import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
} from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import AnTextField from '@components/AnTextField';
import AnCheckoutImageLineItem from '@components/AnCheckoutImageLineItem';
import { useDispatch, useSelector } from 'react-redux';
import {
  addToCart,
  removeFromCart,
  selectCoverOptions,
  setCustomBookPurchaseDetails,
} from '@store/checkoutSlice';
import AnButton from '@components/AnButton';
import { LineItem, PRODUCT_NAMES } from '../types';
import useCheckoutNavigation from '../hooks/useCheckoutNavigation';

interface FinalizeCoverProps {
  bookTitle: string;
  byLineName: string;
  onIsFormValid: (isValid: boolean) => void;
}

// Define the validation schema
const validationSchema = yup.object().shape({
  bookTitle: yup.string().required('Please enter a book title'),
  byLineName: yup.string().required('Please enter a byline name'),
});

export default function FinalizeCover({
  bookTitle,
  byLineName,
  onIsFormValid,
}: FinalizeCoverProps) {
  const [selectedCover, setSelectedCover] = useState<LineItem | null>(null);
  const prevTitleRef = useRef(bookTitle);
  const prevBylineRef = useRef(byLineName);
  const dispatch = useDispatch();
  const coverOptions = useSelector(selectCoverOptions);

  const { navigateToNext } = useCheckoutNavigation();

  const {
    control,
    formState: { errors, isValid },
    watch,
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    defaultValues: {
      bookTitle,
      byLineName,
    },
  });

  // Watch for changes in bookTitle and byLineName
  const watchedBookTitle = watch('bookTitle');
  const watchedByLineName = watch('byLineName');

  useEffect(() => {
    onIsFormValid(isValid);
  }, [isValid, onIsFormValid]);

  useEffect(() => {
    if (watchedBookTitle !== prevTitleRef.current || watchedByLineName !== prevBylineRef.current) {
      dispatch(setCustomBookPurchaseDetails({
        title: watchedBookTitle,
        byline: watchedByLineName,
      }));
      prevTitleRef.current = watchedBookTitle;
      prevBylineRef.current = watchedByLineName;
    }
  }, [watchedBookTitle, watchedByLineName, dispatch]);

  const handleCoverSelection = useCallback((newSelectedCover: LineItem | null) => {
    if (!newSelectedCover) return;

    if (selectedCover) {
      dispatch(removeFromCart(selectedCover.priceId));
    }

    setSelectedCover(newSelectedCover);
    dispatch(addToCart({ ...newSelectedCover, quantity: 1 }));
  }, [dispatch, selectedCover]);

  useEffect(() => {
    if (coverOptions.length > 0 && !selectedCover) {
      const standardCover = coverOptions.find((cover) => cover.name === PRODUCT_NAMES.STANDARD_COVER);
      if (standardCover) {
        handleCoverSelection(standardCover);
      }
    }
  }, [coverOptions, handleCoverSelection, selectedCover]);

  return (
    <>
      <form className="pt-4 pb-8 max-w-sm">
        <div className="mb-4">
          <p className="text-sm text-gray-600 pb-1">Book Title</p>
          <AnTextField
            name="bookTitle"
            placeholder="Book Title"
            control={control}
            error={errors.bookTitle !== undefined}
            errorMessage={errors.bookTitle?.message}
            textFieldStyle="fully-enclosed"
          />
        </div>
        <div className="mb-4">
          <p className="text-sm text-gray-600 pb-1">Byline Name</p>
          <AnTextField
            name="byLineName"
            placeholder="Byline Name"
            control={control}
            error={errors.byLineName !== undefined}
            errorMessage={errors.byLineName?.message}
            textFieldStyle="fully-enclosed"
          />
        </div>
        <AnCheckoutImageLineItem
          lineItemOptions={coverOptions}
          onChange={handleCoverSelection}
          selectedOption={selectedCover}
          showDescription
        />
      </form>
      <div className="mt-4">
        <AnButton
          onClick={navigateToNext}
          disabled={!isValid}
        >
          Next
        </AnButton>
      </div>
    </>
  );
}
