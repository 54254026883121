import AnTextField from '@components/AnTextField';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch } from '@store/store';
import {
  selectCheckoutData, setCheckoutData, handleCheckoutEmailSignupThunk,
} from '@store/checkoutSlice';
import * as yup from 'yup';
import { isEmail } from 'validator';
import AnButton from '@components/AnButton';
import { CheckoutData } from '../types';
import useCheckoutNavigation from '../hooks/useCheckoutNavigation';

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .test('is-valid-email', 'Please enter a valid email address', (value) => isEmail(value || ''))
    .required('Please enter your email'),
});

export default function EmailPrompt() {
  const dispatch = useDispatch<AppDispatch>();
  const checkoutData = useSelector(selectCheckoutData) as CheckoutData;
  const { navigateToNext } = useCheckoutNavigation();

  const {
    control,
    watch,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    defaultValues: {
      email: checkoutData?.purchaserEmail || '',
    },
  });

  const watchedEmail = watch('email');

  const handleContinue = async () => {
    dispatch(setCheckoutData({
      ...checkoutData,
      purchaserEmail: watchedEmail,
    }));
    try {
      dispatch(handleCheckoutEmailSignupThunk({ email: watchedEmail })).unwrap();
      navigateToNext();
    } catch (error) {
      console.error('Failed to handle checkout email signup:', error);
      // Optionally handle error UI here
    }
  };

  return (
    <div className="flex flex-col space-y-6 my-4">
      <div className="max-w-sm">
        <form>
          <div className="mb-1">
            <p className="text-sm text-gray-600 pb-1">What&apos;s your email address?</p>
            <AnTextField
              name="email"
              control={control}
              type="email"
              placeholder="first.last@example.com"
              error={!!errors.email}
              errorMessage={errors.email?.message}
              textFieldStyle="fully-enclosed"
              autoComplete="email"
            />
          </div>
        </form>

        <div className="flex flex-col">
          <AnButton
            onClick={handleContinue}
            disabled={!isValid}
          >
            Continue
          </AnButton>
        </div>

        <div className="p-3 text-sm text-gray-600 text-center">
          By continuing, you agree to our
          {' '}
          <a href="https://www.wander.ly/terms" className="text-primary hover:underline">terms of service</a>
          {' '}
          and
          {' '}
          <a href="https://www.wander.ly/privacy" className="text-primary hover:underline">privacy policy</a>
          .
        </div>
      </div>
    </div>
  );
}
