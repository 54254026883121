import { Helmet } from 'react-helmet-async';
import { DEFAULT_META, SECTION_META } from './DefaultMeta';

export interface SeoMetaProps {
  title?: string;
  description?: string;
  images?: {
    default?: string;
    openGraph?: string;
    twitter?: string;
  };
  type?: 'website' | 'article';
  canonicalUrl?: string;
  noindex?: boolean;
  structuredData?: Record<string, any>;
}

export default function SeoMeta({
  title = DEFAULT_META.title,
  description = DEFAULT_META.description,
  images = DEFAULT_META.images,
  type = DEFAULT_META.type,
  canonicalUrl,
  noindex = false,
  structuredData,
}: SeoMetaProps) {
  const url = canonicalUrl ? `${DEFAULT_META.domain}${canonicalUrl}` : DEFAULT_META.domain;
  const ogImage = `${DEFAULT_META.domain}${images.openGraph || images.default || DEFAULT_META.images.openGraph || DEFAULT_META.images.default}`.replace(/([^:]\/)\/+/g, '$1');

  return (
    <Helmet>
      {/* Basic */}
      <title>{title}</title>
      <meta name="description" content={description} />
      {noindex && <meta name="robots" content="noindex,nofollow" />}
      {canonicalUrl && <link rel="canonical" href={url} />}

      {/* Open Graph */}
      <meta property="og:type" content={type} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={ogImage} />
      <meta property="og:url" content={url} />
      <meta property="og:site_name" content="Wanderly" />

      {/* Twitter Card */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content={DEFAULT_META.twitterHandle} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={ogImage} />

      {/* Add structured data */}
      <script type="application/ld+json">
        {JSON.stringify(
          structuredData || {
            '@context': 'https://schema.org',
            '@type': 'WebSite',
            url: DEFAULT_META.domain,
            name: 'Wanderly',
            description: DEFAULT_META.description,
            potentialAction: [
              {
                '@type': 'ViewAction',
                target: [
                  {
                    '@type': 'EntryPoint',
                    urlTemplate: `${DEFAULT_META.domain}#try-the-app`,
                    name: SECTION_META.tryTheApp.title,
                    description: SECTION_META.tryTheApp.description,
                  },
                  {
                    '@type': 'EntryPoint',
                    urlTemplate: `${DEFAULT_META.domain}#features`,
                    name: SECTION_META.features.title,
                    description: SECTION_META.features.description,
                  },
                  {
                    '@type': 'EntryPoint',
                    urlTemplate: `${DEFAULT_META.domain}#how-it-works`,
                    name: SECTION_META.howItWorks.title,
                    description: SECTION_META.howItWorks.description,
                  },
                  {
                    '@type': 'EntryPoint',
                    urlTemplate: `${DEFAULT_META.domain}#faq`,
                    name: SECTION_META.faq.title,
                    description: SECTION_META.faq.description,
                  },
                ],
              },
            ],
          },
        )}
      </script>
    </Helmet>
  );
}
