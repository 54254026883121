/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  Cart,
  CartItem,
  CheckoutData,
  CustomBookPurchase,
  LineItem,
  PackageFlowType,
  PackagePurchase,
  PRODUCT_NAMES,
  PurchaseDetails,
  ShippingOption,
  PackageProductName,
  FacebookConversionData,
  EmailListDiscount,
  SocialPost,
  CheckoutStepName,
  WanderlyReason,
} from '@features/Checkout/types';
import getPricesEtc from '@features/Checkout/api/getPricesEtc';
import getShippingOptions from '@features/Checkout/api/getShippingOptions';
import getStripeCheckoutSessionNoAuth from '@features/Checkout/api/getStripeCheckoutSessionNoAuth';
import getNoAuthMetadata from '@lib/api/getNoAuthMetadata';
import { imageBaseURL } from '@lib/imageUtils';
import { MarketingSignupProperties, MarketingSignupResponse } from '@lib/marketingTypes';
import submitMarketingSignup from '@lib/api/postMarketingSignupNoAuth';
import getNextStep from '@features/Checkout/utils/CheckoutFlowManager';
import trackCheckoutEvent from '@lib/analytics';
import { RootState, AppDispatch } from './store';

interface CoverOption extends LineItem {
  imageSrc: string;
}

interface CheckoutState {
  promotionString: string | null;
  isCheckoutInitialized: boolean;
  isCheckoutSessionLoading: boolean;
  checkoutType: string | null;
  cart: Cart;
  selectedShippingOption?: ShippingOption;
  purchaseDetails?: PurchaseDetails;
  packageFlow: PackageFlowType;
  loading: boolean;
  error: Record<string, string | undefined>;
  checkoutData?: CheckoutData;
  availableLineItems: LineItem[];
  shippingOptions: ShippingOption[];
  coverOptions: CoverOption[];
  landingPageActionsLoading: boolean;
  landingPageActionsError: Record<string, string | undefined>;
  marketingOptIn: boolean;
  emailListDiscount: EmailListDiscount | null;
  isNoAuthMetadataInitialized: boolean;
  socialPosts: SocialPost[];
  clientSecret: string | null;
  checkoutVariant: 'control' | 'test' | null;
  currentStep: CheckoutStepName | null;
  navigationHistory: CheckoutStepName[];
}

const initialState: CheckoutState = {
  promotionString: null,
  isCheckoutInitialized: false,
  isCheckoutSessionLoading: false,
  checkoutType: null,
  cart: {
    items: [],
    total: 0,
  },
  packageFlow: {
    purchaseType: '',
    whoWillCreate: '',
    reasons: [],
  },
  loading: false,
  error: {},
  availableLineItems: [],
  shippingOptions: [],
  coverOptions: [],
  landingPageActionsLoading: false,
  landingPageActionsError: {},
  marketingOptIn: false,
  emailListDiscount: null,
  isNoAuthMetadataInitialized: false,
  socialPosts: [],
  clientSecret: null,
  checkoutVariant: null,
  currentStep: null,
  navigationHistory: [],
};

const packageImageMap: Record<PackageProductName, string> = {
  // [PRODUCT_NAMES.THREE_MONTH_SUBSCRIPTION]: 'https://wanderlyimagesmasterresized.s3.amazonaws.com/character_set/0k70vx/default_488w.png',
  // [PRODUCT_NAMES.YEAR_SUBSCRIPTION]: 'https://wanderlyimagesmasterresized.s3.amazonaws.com/character_set/0k70vx/default_488w.png',
  [PRODUCT_NAMES.CUSTOM_BOOK_PLUS_10_CREDITS]: 'https://static.wixstatic.com/media/af1b7c_6c9207d48b6e4f9699b9b932b5caa0bc~mv2.png/v1/fill/w_1278,h_1278,al_c,usm_0.66_1.00_0.01/af1b7c_6c9207d48b6e4f9699b9b932b5caa0bc~mv2.png',
  [PRODUCT_NAMES.STANDARD_PACKAGE]: `${imageBaseURL}misc/checkout/1-book-package_320w.webp`,
  [PRODUCT_NAMES.YEAR_WITH_BOOKS]: `${imageBaseURL}misc/checkout/4-book-package_320w.webp`,
};

export const checkoutSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    setPromotionString: (state, action: PayloadAction<string | null>) => {
      state.promotionString = action.payload;
    },
    addToCart: (state, action: PayloadAction<CartItem>) => {
      const existingItem = state.cart.items.find(
        (item) => item.priceId === action.payload.priceId,
      );

      if (existingItem) {
        existingItem.quantity += action.payload.quantity;
      } else {
        state.cart.items.push(action.payload);
      }

      // Recalculate total
      state.cart.total = state.cart.items.reduce(
        (sum, item) => sum + item.amount * item.quantity,
        0,
      );
    },

    removeFromCart: (state, action: PayloadAction<string>) => {
      state.cart.items = state.cart.items.filter(
        (item) => item.priceId !== action.payload,
      );

      // Recalculate total
      state.cart.total = state.cart.items.reduce(
        (sum, item) => sum + item.amount * item.quantity,
        0,
      );
    },

    updateCartItemQuantity: (
      state,
      action: PayloadAction<{ priceId: string; quantity: number }>,
    ) => {
      const item = state.cart.items.find(
        (i) => i.priceId === action.payload.priceId,
      );
      if (item) {
        item.quantity = action.payload.quantity;
        // Recalculate total
        state.cart.total = state.cart.items.reduce(
          (sum, cartItem) => sum + cartItem.amount * cartItem.quantity,
          0,
        );
      }
    },

    setShippingOption: (state, action: PayloadAction<ShippingOption>) => {
      state.selectedShippingOption = action.payload;
    },

    setCustomBookPurchaseDetails: (state, action: PayloadAction<Partial<CustomBookPurchase>>) => {
      state.purchaseDetails = {
        ...state.purchaseDetails as CustomBookPurchase,
        ...action.payload,
      };
    },

    setPackagePurchaseDetails: (state, action: PayloadAction<Partial<PackagePurchase>>) => {
      const payload = {
        ...action.payload,
        // Only process deliveryDate if it's included in the payload
        ...(action.payload.deliveryDate !== undefined && {
          deliveryDate: action.payload.deliveryDate instanceof Date
            ? action.payload.deliveryDate.toISOString()
            : action.payload.deliveryDate,
        }),
      };

      state.purchaseDetails = {
        ...state.purchaseDetails as PackagePurchase,
        ...payload,
      } as PackagePurchase;
    },

    setPackageFlow: (state, action: PayloadAction<Partial<PackageFlowType>>) => {
      state.packageFlow = {
        ...state.packageFlow,
        ...action.payload,
      };
    },

    setCheckoutData: (state, action: PayloadAction<CheckoutData>) => {
      state.checkoutData = action.payload;
    },

    setCheckoutInitialized: (state, action: PayloadAction<boolean>) => {
      state.isCheckoutInitialized = action.payload;
    },

    setIsCheckoutSessionLoading: (state, action: PayloadAction<boolean>) => {
      state.isCheckoutSessionLoading = action.payload;
    },

    resetCheckout: (state) => {
      const savedMarketingOptIn = state.marketingOptIn;
      const savedCheckoutVariant = state.checkoutVariant;
      return {
        ...initialState,
        marketingOptIn: savedMarketingOptIn,
        checkoutVariant: savedCheckoutVariant,
      };
    },

    resetLandingPageError: (state) => {
      state.landingPageActionsError = {};
    },

    setMarketingOptIn: (state, action: PayloadAction<boolean>) => {
      state.marketingOptIn = action.payload;
    },

    setEmailListDiscount: (state, action: PayloadAction<EmailListDiscount | null>) => {
      state.emailListDiscount = action.payload;
    },

    setSocialPosts: (state, action: PayloadAction<SocialPost[]>) => {
      state.socialPosts = action.payload;
    },

    setCheckoutVariant: (state, action: PayloadAction<'control' | 'test'>) => {
      state.checkoutVariant = action.payload;
    },

    setCurrentStep: (state, action: PayloadAction<CheckoutStepName>) => {
      state.currentStep = action.payload;
      state.navigationHistory.push(action.payload);
    },

    navigateBack: (state) => {
      if (state.navigationHistory.length > 1) {
        state.navigationHistory.pop();
        state.currentStep = state.navigationHistory[state.navigationHistory.length - 1];
      }
    },

    goToStep: (state, action: PayloadAction<CheckoutStepName>) => {
      const targetStep = action.payload;
      const targetIndex = state.navigationHistory.indexOf(targetStep);

      if (targetIndex !== -1) {
        // Keep history up to and including the target step
        state.navigationHistory = state.navigationHistory.slice(0, targetIndex + 1);
        state.currentStep = targetStep;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(initializeCheckout.pending, (state) => {
        state.loading = true;
        state.error = {};
      })
      .addCase(initializeCheckout.fulfilled, (state, action) => {
        state.loading = false;
        state.isCheckoutInitialized = true;
        state.availableLineItems = action.payload.lineItems;
        state.shippingOptions = action.payload.shippingOptions;
        state.checkoutType = action.payload.type;

        // Initialize cart if we have an initial item
        if (action.payload.initialCartItem) {
          state.cart.items = [action.payload.initialCartItem];
          state.cart.total = action.payload.initialCartItem.amount * action.payload.initialCartItem.quantity;
        }

        // Set up cover options
        const standardCover: CoverOption = {
          priceId: '',
          productId: '',
          name: PRODUCT_NAMES.STANDARD_COVER,
          description: "Your child's avatar on top of a background illustration.",
          currency: 'usd',
          amount: 0,
          imageSrc: `${imageBaseURL}misc/checkout/standard-cover-art_320w.webp`,
        };

        const premiumCover = action.payload.lineItems.find(
          (item) => item.name === PRODUCT_NAMES.PREMIUM_COVER,
        );

        const premiumCoverArtImageSrc = `${imageBaseURL}misc/checkout/premium-cover-art_320w.webp`;

        state.coverOptions = premiumCover
          ? [standardCover, { ...premiumCover, imageSrc: premiumCoverArtImageSrc }]
          : [standardCover];

        // Set variant during initialization if not already set
        console.log('state.checkoutVariant', state.checkoutVariant);
        if (!state.checkoutVariant || state.checkoutVariant === null || state.checkoutVariant === undefined) {
          state.checkoutVariant = Math.random() < 0.5 ? 'test' : 'control';
        }
      })
      .addCase(initializeCheckout.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          init: action.payload as string,
        };
      })
      .addCase(submitCheckout.pending, (state) => {
        state.isCheckoutSessionLoading = true;
        state.clientSecret = null;
      })
      .addCase(submitCheckout.fulfilled, (state, action) => {
        state.isCheckoutSessionLoading = false;
        if (action.payload && typeof action.payload === 'object') {
          state.clientSecret = action.payload.clientSecret;
        }
      })
      .addCase(submitCheckout.rejected, (state) => {
        state.isCheckoutSessionLoading = false;
        state.clientSecret = null;
      })
      .addCase(submitMarketingSignupThunk.pending, (state) => {
        state.landingPageActionsLoading = true;
        state.landingPageActionsError = {};
      })
      .addCase(submitMarketingSignupThunk.fulfilled, (state) => {
        state.landingPageActionsLoading = false;
        // You could potentially set other state here, like hasSignedUpForMarketing: true
      })
      .addCase(submitMarketingSignupThunk.rejected, (state, action) => {
        state.landingPageActionsLoading = false;
        state.landingPageActionsError = {
          marketingSignup: action.payload as string,
        };
      })
      .addCase(fetchNoAuthMetadata.fulfilled, (state) => {
        state.isNoAuthMetadataInitialized = true;
      });
  },
});

// Actions
export const {
  setPromotionString,
  addToCart,
  removeFromCart,
  updateCartItemQuantity,
  setShippingOption,
  setCustomBookPurchaseDetails,
  setPackagePurchaseDetails,
  setPackageFlow,
  setCheckoutData,
  setCheckoutInitialized,
  resetCheckout,
  resetLandingPageError,
  setMarketingOptIn,
  setEmailListDiscount,
  setSocialPosts,
  setCheckoutVariant,
  setCurrentStep,
  navigateBack,
  goToStep,
} = checkoutSlice.actions;

// Selectors
export const selectPromotionString = (state: RootState) => state.checkout.promotionString;
export const selectCheckoutType = (state: RootState) => state.checkout.checkoutType;
export const selectCart = (state: RootState) => state.checkout.cart;
export const selectCartTotal = (state: RootState) => state.checkout.cart.total;
export const selectCartItems = (state: RootState) => state.checkout.cart.items;
export const selectShippingOption = (state: RootState) => state.checkout.selectedShippingOption;
export const selectPurchaseDetails = (state: RootState) => state.checkout.purchaseDetails;
export const selectPackageFlow = (state: RootState) => state.checkout.packageFlow;
export const selectCheckoutData = (state: RootState) => state.checkout.checkoutData;
export const selectLoading = (state: RootState) => state.checkout.loading;
export const selectIsCheckoutInitialized = (state: RootState) => state.checkout.isCheckoutInitialized;
export const selectAvailableLineItems = (state: RootState) => state.checkout.availableLineItems;
export const selectShippingOptions = (state: RootState) => state.checkout.shippingOptions;
export const selectCoverOptions = (state: RootState) => state.checkout.coverOptions;
export const selectIsCheckoutSessionLoading = (state: RootState) => state.checkout.isCheckoutSessionLoading;
export const selectLandingPageActionsLoading = (state: RootState) => state.checkout.landingPageActionsLoading;
export const selectLandingPageActionsError = (state: RootState) => state.checkout.landingPageActionsError;
export const selectMarketingOptIn = (state: RootState) => state.checkout.marketingOptIn;
export const selectEmailListDiscount = (state: RootState) => state.checkout.emailListDiscount;
export const selectIsNoAuthMetadataInitialized = (state: RootState) => state.checkout.isNoAuthMetadataInitialized;
export const selectSocialPosts = (state: RootState) => state.checkout.socialPosts;
export const selectClientSecret = (state: RootState) => state.checkout.clientSecret;
export const selectCheckoutVariant = (state: RootState) => state.checkout.checkoutVariant;
export const selectCurrentStep = (state: RootState) => state.checkout.currentStep;
export const selectNavigationHistory = (state: RootState) => state.checkout.navigationHistory;

export const selectParsedLineItems = (state: RootState) => {
  const { availableLineItems } = state.checkout;

  const customBook = availableLineItems.find((item) => item.name === PRODUCT_NAMES.CUSTOM_BOOK) || null;
  const premiumCover = availableLineItems.find((item) => item.name === PRODUCT_NAMES.PREMIUM_COVER) || null;
  const packageProductNames: PackageProductName[] = [
    // PRODUCT_NAMES.THREE_MONTH_SUBSCRIPTION,
    // PRODUCT_NAMES.YEAR_SUBSCRIPTION,
    PRODUCT_NAMES.CUSTOM_BOOK_PLUS_10_CREDITS,
    PRODUCT_NAMES.STANDARD_PACKAGE,
    PRODUCT_NAMES.YEAR_WITH_BOOKS,
  ];

  const packageOptions = availableLineItems
    .filter((item): item is LineItem => packageProductNames.includes(item.name as PackageProductName))
    .sort((a, b) => a.amount - b.amount)
    .map((item) => ({
      ...item,
      imageSrc: packageImageMap[item.name as PackageProductName],
    }));

  return {
    customBookLineItem: customBook ? {
      ...customBook,
      imageSrc: 'https://wanderlyimagesmasterresized.s3.amazonaws.com/character_set/0k70vx/default_488w.png',
    } : null,
    premiumArtCoverLineItem: premiumCover ? {
      ...premiumCover,
      imageSrc: 'https://wanderlyimagesmasterresized.s3.amazonaws.com/character_set/0k70vx/default_488w.png',
    } : null,
    packageOptions,
  };
};

export const selectPackageOptions = (state: RootState) => selectParsedLineItems(state).packageOptions;

// If you need any async thunks, you can add them here
// Example:
/*
export const submitCheckout = createAsyncThunk(
  'checkout/submit',
  async (_, thunkAPI) => {
    // Implementation
  }
);
*/

export const fetchNoAuthMetadata = createAsyncThunk(
  'checkout/fetchNoAuthMetadata',
  async (_, { dispatch }) => {
    try {
      const metadata = await getNoAuthMetadata();
      if (metadata?.promotionString) {
        dispatch(setPromotionString(metadata.promotionString));
      }
      if (metadata?.emailListDiscount) {
        dispatch(setEmailListDiscount(metadata.emailListDiscount));
      }
      if (metadata?.socialPosts) {
        dispatch(setSocialPosts(metadata.socialPosts));
      }
    } catch (error) {
      // Silently fail for metadata - it's not critical
      console.error('Failed to fetch metadata:', error);
    }
  },
);

export const initializeCheckout = createAsyncThunk<
  {
    lineItems: LineItem[];
    shippingOptions: ShippingOption[];
    initialCartItem?: CartItem;
    type: string | null;
    initDuration?: number;
  },
  { type: string | null },
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: string;
  }
>(
  'checkout/initializeCheckout',
  async ({ type }, { rejectWithValue }) => {
    try {
      const startTime = performance.now();

      // Fetch checkout-specific APIs in parallel
      const [lineItems, fetchedShippingOptions] = await Promise.all([
        getPricesEtc(),
        getShippingOptions(),
      ]);

      const endTime = performance.now();
      const initDuration = endTime - startTime;

      if (!lineItems) {
        throw new Error('No response from getPricesEtc');
      }

      // Sort shipping options from cheapest to most expensive
      const sortedShippingOptions = fetchedShippingOptions.sort((a: { fixedAmount: { amount: number; }; }, b: { fixedAmount: { amount: number; }; }) => a.fixedAmount.amount - b.fixedAmount.amount);

      // Initialize cart based on type
      let initialCartItem: CartItem | undefined;

      if (type === 'custom-book') {
        const customBookLineItem = lineItems.find(
          (item: LineItem) => item.name === PRODUCT_NAMES.CUSTOM_BOOK,
        );
        if (customBookLineItem) {
          initialCartItem = { ...customBookLineItem, quantity: 1 };
        }
      } else if (type === 'buy-package') {
        const giftCardItem = lineItems.find(
          (item: LineItem) => item.name === PRODUCT_NAMES.STANDARD_PACKAGE,
        );
        if (giftCardItem) {
          initialCartItem = { ...giftCardItem, quantity: 1 };
        }
      }

      return {
        lineItems,
        shippingOptions: sortedShippingOptions,
        initialCartItem,
        type,
        initDuration,
      };
    } catch (error) {
      return rejectWithValue(error instanceof Error ? error.message : 'Failed to initialize checkout');
    }
  },
);

export const submitCheckout = createAsyncThunk(
  'checkout/submit',
  async ({
    email, cart, shippingOptions, fbConversionData, embedded = false,
  }: {
    email: string;
    cart: Cart;
    shippingOptions: ShippingOption[];
    fbConversionData: FacebookConversionData;
    embedded?: boolean;
  }, { getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      const checkoutType = selectCheckoutType(state) as 'custom-book' | 'buy-package';

      const response = await getStripeCheckoutSessionNoAuth({
        email,
        lineItems: cart.items,
        shippingOptions,
        type: checkoutType,
        purchaseDetails: state.checkout.purchaseDetails,
        fbConversionData,
        embedded,
      });

      // Handle embedded checkout response
      if (embedded && typeof response === 'object') {
        return response;
      }

      // Handle redirect checkout response
      if (typeof response === 'string') {
        window.location.href = response;
        return response;
      }

      throw new Error('Invalid response format');
    } catch (error) {
      return rejectWithValue(error instanceof Error ? error.message : 'Failed to create checkout session');
    }
  },
);

export const submitMarketingSignupThunk = createAsyncThunk(
  'checkout/submitMarketingSignup',
  async (signupData: MarketingSignupProperties, { getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      const emailListDiscount = selectEmailListDiscount(state);

      const response: MarketingSignupResponse = await submitMarketingSignup({
        ...signupData,
        // Only include discount if explicitly requested
        discountInfo: signupData.includeDiscount ? emailListDiscount || undefined : undefined,
      });

      if (response.success) {
        return response;
      }

      // Handle the already subscribed case
      if (response.code === 'ALREADY_SUBSCRIBED') {
        return rejectWithValue(response.message);
      }

      return rejectWithValue(response.message || 'Failed to sign up');
    } catch (error) {
      return rejectWithValue('Something went wrong. Please try again later.');
    }
  },
);

export const navigateToNextStep = createAsyncThunk(
  'checkout/navigateToNext',
  (_, { getState, dispatch }) => {
    const state = getState() as RootState;
    const currentStep = selectCurrentStep(state);
    const flowData = selectPackageFlow(state);
    const checkoutVariant = selectCheckoutVariant(state);

    console.log('currentStep in navigateToNext', currentStep);
    console.log('flowData in navigateToNext', flowData);

    const nextStep = getNextStep(currentStep, {
      ...flowData,
      checkoutVariant: checkoutVariant || null,
    });
    console.log('nextStep in navigateToNext', nextStep);
    if (nextStep) {
      dispatch(setCurrentStep(nextStep));
    }
  },
);

export const navigateBackStep = createAsyncThunk(
  'checkout/navigateBack',
  (_, { dispatch, getState }) => {
    const state = getState() as RootState;
    const history = selectNavigationHistory(state);

    if (history.length > 1) {
      dispatch(navigateBack());
    }
  },
);

export const goToNamedStep = createAsyncThunk<
  void,
  CheckoutStepName,
  { state: RootState }
>(
  'checkout/goToNamedStep',
  async (targetStep, { dispatch }) => {
    dispatch(goToStep(targetStep));
  },
);

export const handleMarketingSignupThunk = createAsyncThunk(
  'checkout/handleMarketingSignup',
  async (data: { email: string }, { getState, dispatch }) => {
    const state = getState() as RootState;
    const packageFlow = selectPackageFlow(state);
    const checkoutVariant = selectCheckoutVariant(state);

    const reasonsMap = {
      creativityConfidence: packageFlow.reasons.includes(WanderlyReason.CREATIVITY_CONFIDENCE),
      personalizedStories: packageFlow.reasons.includes(WanderlyReason.PERSONALIZED_STORIES),
      connectWith: packageFlow.reasons.includes(WanderlyReason.CONNECT_WITH),
      customIllustrations: packageFlow.reasons.includes(WanderlyReason.CUSTOM_ILLUSTRATIONS),
      readingPractice: packageFlow.reasons.includes(WanderlyReason.READING_PRACTICE),
      personalizedBooks: packageFlow.reasons.includes(WanderlyReason.PERSONALIZED_BOOKS),
    };

    const signupData = {
      ...data,
      source: 'checkout_flow',
      customProperties: {
        formLocation: 'checkout_email_prompt',
        purchaseType: packageFlow.purchaseType,
        whoWillCreate: packageFlow.whoWillCreate,
        ...reasonsMap,
      },
    };

    try {
      dispatch(resetLandingPageError());
      const resultAction = await dispatch(submitMarketingSignupThunk(signupData));

      if (resultAction.payload === "You're already signed up! Check your email for the discount code.") {
        dispatch(resetLandingPageError());
        trackCheckoutEvent('email_signup_success', {
          step: CheckoutStepName.EMAIL,
          action: 'complete',
          signup_status: 'existing_subscriber',
          variant: checkoutVariant,
        });
        return resultAction.payload;
      }

      if (submitMarketingSignupThunk.fulfilled.match(resultAction)) {
        trackCheckoutEvent('email_signup_success', {
          step: CheckoutStepName.EMAIL,
          action: 'complete',
          signup_status: 'new_subscriber',
          variant: checkoutVariant,
        });
        return resultAction.payload;
      }

      trackCheckoutEvent('email_signup_error', {
        step: CheckoutStepName.EMAIL,
        error: resultAction.payload as string,
        variant: checkoutVariant,
      });
      return resultAction.payload;
    } catch (error) {
      console.error('Unexpected error during marketing signup:', error);
      trackCheckoutEvent('email_signup_error', {
        step: CheckoutStepName.EMAIL,
        error: 'unexpected_error',
        variant: checkoutVariant,
      });
      dispatch(resetLandingPageError());
      throw error;
    }
  },
);

// Add new thunk for checkout flow email signup
export const handleCheckoutEmailSignupThunk = createAsyncThunk(
  'checkout/handleCheckoutEmailSignup',
  async (data: { email: string }, { getState, dispatch }) => {
    const state = getState() as RootState;
    const packageFlow = selectPackageFlow(state);
    const checkoutVariant = selectCheckoutVariant(state);

    // Convert reasons array to individual boolean properties
    const reasonsMap = {
      creativityConfidence: packageFlow.reasons.includes(WanderlyReason.CREATIVITY_CONFIDENCE),
      personalizedStories: packageFlow.reasons.includes(WanderlyReason.PERSONALIZED_STORIES),
      connectWith: packageFlow.reasons.includes(WanderlyReason.CONNECT_WITH),
      customIllustrations: packageFlow.reasons.includes(WanderlyReason.CUSTOM_ILLUSTRATIONS),
      readingPractice: packageFlow.reasons.includes(WanderlyReason.READING_PRACTICE),
      personalizedBooks: packageFlow.reasons.includes(WanderlyReason.PERSONALIZED_BOOKS),
    };

    const signupData: MarketingSignupProperties = {
      email: data.email,
      source: 'checkout_flow',
      includeDiscount: false, // Explicitly exclude discount
      triggerEvent: 'checkout_email_capture',
      customProperties: {
        formLocation: 'checkout_email_prompt',
        purchaseType: packageFlow.purchaseType,
        whoWillCreate: packageFlow.whoWillCreate,
        // Spread the individual reason booleans instead of passing array
        ...reasonsMap,
      },
    };

    try {
      dispatch(resetLandingPageError());
      const resultAction = await dispatch(submitMarketingSignupThunk(signupData));

      if (submitMarketingSignupThunk.fulfilled.match(resultAction)) {
        trackCheckoutEvent('checkout_email_capture_success', {
          step: CheckoutStepName.EMAIL,
          action: 'complete',
          signup_status: 'new_subscriber',
          variant: checkoutVariant,
        });
        return resultAction.payload;
      }

      // Handle existing subscriber case
      if (resultAction.payload === "You're already signed up! Check your email for the discount code.") {
        dispatch(resetLandingPageError());
        trackCheckoutEvent('checkout_email_capture_success', {
          step: CheckoutStepName.EMAIL,
          action: 'complete',
          signup_status: 'existing_subscriber',
          variant: checkoutVariant,
        });
        return resultAction.payload;
      }

      trackCheckoutEvent('checkout_email_capture_error', {
        step: CheckoutStepName.EMAIL,
        error: resultAction.payload as string,
        variant: checkoutVariant,
      });
      return resultAction.payload;
    } catch (error) {
      console.error('Unexpected error during checkout email capture:', error);
      trackCheckoutEvent('checkout_email_capture_error', {
        step: CheckoutStepName.EMAIL,
        error: 'unexpected_error',
        variant: checkoutVariant,
      });
      dispatch(resetLandingPageError());
      throw error;
    }
  },
);

export default checkoutSlice.reducer;
