import { ReactNode, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@store/store';
import { fetchNoAuthMetadata, selectIsNoAuthMetadataInitialized } from '@store/checkoutSlice';

interface NoAuthPageWrapperProps {
  children: ReactNode;
}

function NoAuthPageWrapper({ children }: NoAuthPageWrapperProps) {
  const dispatch = useDispatch<AppDispatch>();
  const isInitialized = useSelector(selectIsNoAuthMetadataInitialized);

  useEffect(() => {
    if (!isInitialized) {
      dispatch(fetchNoAuthMetadata());
    }
  }, [dispatch, isInitialized]);

  return (
    <div className="no-auth-wrapper">
      {children}
    </div>
  );
}

export default NoAuthPageWrapper;
