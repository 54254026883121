import { ReactNode } from 'react';

export const PRODUCT_NAMES = {
  CUSTOM_BOOK: 'A Custom Wanderly Book',
  PREMIUM_COVER: 'Premium Cover Art',
  STANDARD_COVER: 'Standard Cover Art',
  CUSTOM_BOOK_PLUS_10_CREDITS: 'Custom Book + 10 Credits',
  // THREE_MONTH_SUBSCRIPTION: '3 Months of Stories',
  // YEAR_SUBSCRIPTION: 'A Year of Stories',
  STANDARD_PACKAGE: 'Custom Book + 3 Months',
  YEAR_WITH_BOOKS: '4 Custom Books + 1 Year',
  CLASSIC_BOOKPLATE: 'Classic Bookplate',
  MODERN_BOOKPLATE: 'Modern Bookplate',
  GENERAL_GC_STYLE: 'Yay! Enjoy some magical stories!',
  HOLIDAY_GC_STYLE: "It's a wonderful time of year for stories...",
  BIRTHDAY_GC_STYLE: 'Happy Birthday!',
} as const;

export type PackageProductName =
  // | typeof PRODUCT_NAMES.THREE_MONTH_SUBSCRIPTION
  // | typeof PRODUCT_NAMES.YEAR_SUBSCRIPTION
  | typeof PRODUCT_NAMES.CUSTOM_BOOK_PLUS_10_CREDITS
  | typeof PRODUCT_NAMES.STANDARD_PACKAGE
  | typeof PRODUCT_NAMES.YEAR_WITH_BOOKS;

export type ProductName = typeof PRODUCT_NAMES[keyof typeof PRODUCT_NAMES];

export interface LineItem {
  priceId: string;
  productId: string;
  name: ProductName;
  description: string;
  currency: string;
  amount: number; // this is the amount in cents
  imageSrc?: string; // for use within the checkout page UI
}

export interface CheckoutData {
  purchaserEmail: string;
  lineItems: LineItem[];
  successUrl: string;
  cancelUrl: string;
}

export interface CustomBookPurchase {
  title: string;
  byline: string;
  bookplateName: string;
  dedication: string;
  bookplateStyle: string;
  storyId: string;
}

export interface PackagePurchase {
  recipientFirstName: string;
  recipientLastName: string;
  recipientEmail: string;
  giftMessage: string;
  deliveryDate: Date | null;
  giftCardStyle: string;
  gifterName: string;
}

export type PurchaseDetails = CustomBookPurchase | PackagePurchase;

export function isCustomBookPurchase(purchase: PurchaseDetails): purchase is CustomBookPurchase {
  return 'title' in purchase && 'byline' in purchase;
}

export interface CartItem extends LineItem {
  quantity: number;
}

export interface Cart {
  items: CartItem[];
  total: number;
}

export interface ShippingOption {
  id: string;
  displayName: string;
  deliveryEstimate: string;
  fixedAmount: number;
  type: string;
}

export enum WhoWillCreate {
  CHILD = 'Child',
  GRANDCHILD = 'Grandchild',
  NIECE_OR_NEPHEW = 'Niece or Nephew',
  FRIEND = 'Friend',
  OTHER = 'Other',
}

export enum PurchaseType {
  SELF = 'self',
  GIFT = 'gift',
  BOOK_ORDER = 'book_order',
}

export enum WanderlyReason {
  CREATIVITY_CONFIDENCE = '💪 Encourage creativity & confidence',
  PERSONALIZED_STORIES = '💬 Personalized stories',
  CONNECT_WITH = '👪 Connect with',
  CUSTOM_ILLUSTRATIONS = '🎨 Custom illustrations',
  READING_PRACTICE = '🎓 Reading practice',
  PERSONALIZED_BOOKS = '📚 Personalized books',
}

export enum CheckoutStepName {
  PURCHASE_TYPE = 'purchase_type',
  WHO_WILL_CREATE = 'who_will_create',
  WANDERLY_REASON = 'wanderly_reason',
  EMAIL = 'email',
  FINALIZE_COVER = 'finalize_cover',
  PERSONALIZE_INSIDE = 'personalize_inside',
  SELECT_PACKAGE = 'select_package',
  GIFT_DELIVERY = 'gift_delivery',
  CUSTOMIZE_GIFT = 'customize_gift',
  ORDER_SUMMARY = 'order_summary',
  INITIAL_VALUE_PROP = 'initial_value_prop',
  REASON_VALUE_PROPS = 'reason_value_props',
  CREATIVITY_CONFIDENCE_VALUE_PROP = 'creativity_confidence_value_prop',
  PERSONALIZED_STORIES_VALUE_PROP = 'personalized_stories_value_prop',
  CONNECT_WITH_VALUE_PROP = 'connect_with_value_prop',
  CUSTOM_ILLUSTRATIONS_VALUE_PROP = 'custom_illustrations_value_prop',
  READING_PRACTICE_VALUE_PROP = 'reading_practice_value_prop',
  PERSONALIZED_BOOKS_VALUE_PROP = 'personalized_books_value_prop',
}

export interface PackageFlowType {
  purchaseType: PurchaseType | '';
  whoWillCreate: '' | 'me' | WhoWillCreate;
  reasons: WanderlyReason[];
  email?: string;
}

// export interface CheckoutStep {
//   id: string;
//   component: React.ReactElement;
//   isNextButtonVisible?: boolean;
//   onNext?: () => void | Promise<void>;
//   onNextButtonText?: string;
// }

export interface CheckoutStepType {
  name: CheckoutStepName;
  title?: ReactNode;
  children: ReactNode;
  isLastStep?: boolean;
  showNextButton?: boolean;
  isNextDisabled?: boolean;
}

export interface FacebookConversionData {
  fbc: string;
  fbp: string;
}

export interface EmailListDiscount {
  percent: number;
  couponCode: string;
}

export interface SocialPost {
  id: string;
  platform: 'instagram' | 'tiktok';
  username: string;
  embedUrl: string;
}

type ValuePropListElement = {
  text: string;
  icon?: ReactNode;
};

type ValuePropDisplay = {
  imageSrc?: string;
} | {
  valueProps?: ValuePropListElement[];
};

export type ValuePropContent = {
  title: ReactNode;
  subheader: string;
  userQuote?: {
    text: string;
    author: string;
  };
} & ValuePropDisplay;
