import React, {
  useRef, useEffect,
} from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import trackCheckoutEvent from '@lib/analytics';
import { useSelector } from 'react-redux';
import { selectCheckoutVariant, selectPackageFlow } from '@store/checkoutSlice';
import { CheckoutStepName, CheckoutStepType, PurchaseType } from './types';
import CheckoutStepComponent from './CheckoutStep';
import useCheckoutNavigation from './hooks/useCheckoutNavigation';

interface CheckoutWizardProps {
  stepComponents: Record<CheckoutStepName, CheckoutStepType>;
}

export default function CheckoutWizard({ stepComponents }: CheckoutWizardProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  // const stepsRefs = useRef<(HTMLDivElement | null)[]>([]);
  const packageFlow = useSelector(selectPackageFlow);
  const { currentStep, isFirstStep } = useCheckoutNavigation();
  const checkoutVariant = useSelector(selectCheckoutVariant);

  // Add this effect to reset scroll position when step changes
  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = 0;
    }
  }, [currentStep]);

  // Get the current step component based on the currentStep name
  const getCurrentStepComponent = () => {
    // First check if it's a reason value prop step
    if (currentStep?.endsWith('_value_prop')) {
      return stepComponents[currentStep];
    }

    // Then handle other steps
    switch (currentStep) {
      case CheckoutStepName.FINALIZE_COVER:
        return stepComponents[CheckoutStepName.FINALIZE_COVER];
      case CheckoutStepName.PERSONALIZE_INSIDE:
        return stepComponents[CheckoutStepName.PERSONALIZE_INSIDE];
      case CheckoutStepName.PURCHASE_TYPE:
        return stepComponents[CheckoutStepName.PURCHASE_TYPE];
      case CheckoutStepName.WHO_WILL_CREATE:
        return stepComponents[CheckoutStepName.WHO_WILL_CREATE];
      case CheckoutStepName.EMAIL:
        return stepComponents[CheckoutStepName.EMAIL];
      case CheckoutStepName.GIFT_DELIVERY:
        return stepComponents[CheckoutStepName.GIFT_DELIVERY];
      case CheckoutStepName.CUSTOMIZE_GIFT:
        return stepComponents[CheckoutStepName.CUSTOMIZE_GIFT];
      case CheckoutStepName.SELECT_PACKAGE:
        return stepComponents[CheckoutStepName.SELECT_PACKAGE];
      case CheckoutStepName.ORDER_SUMMARY:
        return stepComponents[CheckoutStepName.ORDER_SUMMARY];
      case CheckoutStepName.WANDERLY_REASON:
        return stepComponents[CheckoutStepName.WANDERLY_REASON];
      case CheckoutStepName.INITIAL_VALUE_PROP:
        return stepComponents[CheckoutStepName.INITIAL_VALUE_PROP];
      default:
        return stepComponents[CheckoutStepName.PURCHASE_TYPE];
    }
  };

  const stepComponent = getCurrentStepComponent();

  useEffect(() => {
    if (!currentStep) return;
    trackCheckoutEvent('checkout_step_view', {
      path: packageFlow.purchaseType || undefined,
      step: currentStep.toLowerCase().replace(/\s+/g, '_') as CheckoutStepName,
      action: 'view',
      variant: checkoutVariant,
    });
  }, [currentStep, packageFlow.purchaseType, checkoutVariant]);

  // Track initial step view
  useEffect(() => {
    if (isFirstStep) {
      trackCheckoutEvent('checkout_step_view', {
        path: packageFlow.purchaseType || undefined,
        step: packageFlow.purchaseType === PurchaseType.GIFT ? CheckoutStepName.PURCHASE_TYPE : CheckoutStepName.FINALIZE_COVER,
        action: 'view',
        variant: checkoutVariant,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!currentStep) return null;

  return (
    <div className="h-full">
      <AnimatePresence initial={false} mode="wait">
        <motion.div
          key={currentStep}
          className="h-full"
          initial={{ opacity: 0, y: '50%' }}
          animate={{ opacity: 1, y: '0%' }}
          exit={{ opacity: 0, y: '-50%' }}
          transition={{
            duration: 0.5,
            opacity: { duration: 0.3 },
            y: { type: 'spring', stiffness: 300, damping: 30 },
          }}
        >
          <div
            ref={containerRef}
            className="h-full overflow-y-auto"
          >
            <CheckoutStepComponent
              name={stepComponent.name}
              title={stepComponent.title}
            >
              {stepComponent.children}
            </CheckoutStepComponent>
          </div>
        </motion.div>
      </AnimatePresence>
    </div>
  );
}
