import AnButton from '@components/AnButton';
import { WhoWillCreate as WhoWillCreateEnum } from '../types';
import useCheckoutNavigation from '../hooks/useCheckoutNavigation';

export default function WhoWillCreate() {
  const { updatePackageFlow, navigateToNext, flowData } = useCheckoutNavigation();

  const handleSelection = async (creator: WhoWillCreateEnum) => {
    await updatePackageFlow({
      ...flowData,
      whoWillCreate: creator,
    });
    navigateToNext();
  };

  return (
    <div className="flex justify-between flex-col space-y-4 my-4 pr-1">
      <p className="text-sm text-gray-600">
        Pick the option that best applies.
      </p>
      {Object.values(WhoWillCreateEnum).map((creator) => (
        <AnButton
          key={creator}
          color="primary"
          variant="outlined"
          className="bg-white !text-black !font-[Urbanist]"
          onClick={() => handleSelection(creator)}
        >
          {creator}
        </AnButton>
      ))}
    </div>
  );
}
