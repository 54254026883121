export const DEFAULT_META = {
  title: 'wanderly: discover your child\'s story',
  description: 'Create magical bedtime stories together. Include your child\'s friends, family and interests. Turn the stories into a beautifully printed book, made just for them.',
  images: {
    default: '/social-share.jpg',
    openGraph: '/social-share-og.jpg',
    twitter: '/social-share.jpg',
  },
  twitterHandle: '@wanderlystories',
  domain: 'https://wander.ly',
  type: 'website' as const,
} as const;

export const PAGE_META = {
  home: {
    title: DEFAULT_META.title,
    description: DEFAULT_META.description,
  },
  privacy: {
    title: 'Privacy Policy | Wanderly',
    description: 'Learn about how Wanderly protects and handles your personal information.',
  },
  terms: {
    title: 'Terms of Service | Wanderly',
    description: 'Read about the terms and conditions for using Wanderly\'s story creation service.',
  },
  checkout: {
    title: 'Get Started with Wanderly | Buy or Gift Packages and Books',
    description: 'Choose your package and start creating personalized stories with your child today.',
    noindex: false,
  },
} as const;

export const SECTION_META = {
  tryTheApp: {
    title: 'Try the Wanderly App',
    description: 'Available on iOS and Android. Choose characters, plot points, and watch the story unfold.',
  },
  features: {
    title: 'Premium Personalized Books',
    description: 'Bring your favorite stories to life with premium hardcover books and custom illustrations.',
  },
  howItWorks: {
    title: 'How Wanderly Works',
    description: 'Learn how to create personalized stories, customize characters, and turn them into beautiful printed books.',
  },
  faq: {
    title: 'Frequently Asked Questions',
    description: 'Find answers to common questions about Wanderly\'s personalized children\'s books and story creation process.',
  },
} as const;
