import {
  useCallback, useEffect, useMemo, useState, lazy, Suspense,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import yellowOrganicClosedLine from '@assets/yellow_organic_closed_line.svg';
import yellowOrganicBlob from '@assets/yellow_organic_blob.svg';
import yayWebster from '@assets/webster-yay-transparent_640w.png';
import logo512 from '@assets/logo512.png';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@store/store';
import {
  setCustomBookPurchaseDetails,
  setPackagePurchaseDetails,
  selectCart,
  selectPackageFlow,
  selectIsCheckoutInitialized,
  initializeCheckout,
  selectShippingOptions,
  submitCheckout,
  selectPurchaseDetails,
  setCheckoutInitialized,
  selectCheckoutData,
  setCurrentStep,
  selectCheckoutVariant,
} from '@store/checkoutSlice';
import { Loader } from '@aws-amplify/ui-react';
import colors from 'src/colors';
import { imageBaseURL } from '@lib/imageUtils';
import trackCheckoutEvent from '@lib/analytics';
import GALLERY_BOOK_IMAGES from '@features/LandingPage/data/book-carousel';
import { SeoMeta, PAGE_META } from '@components/SEO';
import NoAuthPageWrapper from '@components/NoAuthPageWrapper';
import { loadStripe } from '@stripe/stripe-js';
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';
import AnCrossIcon from '@components/AnIcon/icons/AnCrossIcon';
import AnButton from '@components/AnButton';
import CheckoutHeader from './CheckoutHeader';
import {
  CheckoutStepName,
  CheckoutStepType,
  FacebookConversionData,
  isCustomBookPurchase,
  PRODUCT_NAMES,
  PurchaseType,
  WanderlyReason as WanderlyReasonEnum,
} from './types';
import CheckoutWizard from './CheckoutWizard';
import CheckoutQuickSummary from './CheckoutQuickSummary';
import FinalizeCover from './OrderBookSteps/FinalizeCover';
import PersonalizeInside from './OrderBookSteps/PersonalizeInside';
import OrderSummary from './OrderSummary';
import SelectPackage from './BuyPackage/SelectPackage';
import CustomizeGift from './BuyPackage/CustomizeGift';
import WhoWillCreate from './BuyPackage/WhoWillCreate';
import WhoAreYouBuyingFor from './BuyPackage/WhoAreYouBuyingFor';
import GiftDeliveryInfo from './BuyPackage/GiftDeliveryInfo';
import useMediaQuery from './hooks/useMediaQuery';
import EmailPrompt from './BuyPackage/EmailPrompt';
import WanderlyReason from './BuyPackage/WanderlyReason';
import InitialValueProp from './ValueProp/InitialValueProp';
import ReasonValueProp from './ValueProp/ReasonValueProp';

// At the top of the file, add console logs to debug
console.log('Stripe Key:', process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
console.log('All env vars:', process.env);

// Update the stripePromise initialization with error handling
const stripePromise = (() => {
  const key = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
  if (!key) {
    console.error('Stripe publishable key is missing!');
    return null;
  }
  console.log('Initializing Stripe with key:', key);
  return loadStripe(key);
})();

// Helper functions to get Facebook cookies
function getFacebookPixelCookie() {
  const cookie = document.cookie
    .split('; ')
    .find((row) => row.startsWith('_fbp='))
    ?.split('=')[1];
  return cookie;
}

function getFacebookClickCookie() {
  const cookie = document.cookie
    .split('; ')
    .find((row) => row.startsWith('_fbc='))
    ?.split('=')[1];
  return cookie;
}

const CUSTOM_BOOK_PARAMS = {
  email: {
    entry: 'entry.833608329',
    direct: 'customer_email',
  },
  bookTitle: {
    entry: 'entry.492077904',
    direct: 'book_title',
  },
  byLineName: {
    entry: 'entry.905712201',
    direct: 'byline_name',
  },
  namePlateName: {
    entry: 'entry.1620812457',
    direct: 'nameplate_name',
  },
  message: {
    entry: 'entry.575637385',
    direct: 'dedication_message',
  },
};

type CheckoutLayout = 'single-pane' | 'two-pane';

// function getCheckoutPath(type: string, giftCardFlow: PackageFlowType): CheckoutPath {
//   if (type === 'custom-book') return 'book_order';
//   if (giftCardFlow.buyingFor === 'me') return 'self_purchase';
//   if (giftCardFlow.whoWillCreate === 'me') return 'gift_with_self_writing';
//   return 'gift_package';
// }

// Add lazy loaded ImageCarousel
const ImageCarousel = lazy(() => import('@components/AnImageCarousel'));

export default function Checkout() {
  const [searchParams] = useSearchParams();
  const type = searchParams.get('type') || 'buy-package';
  const isSuccess = searchParams.get('success') === 'true';

  const dispatch = useDispatch<AppDispatch>();

  // checkoutSlice selectors
  const isCheckoutInitialized = useSelector(selectIsCheckoutInitialized);
  const cart = useSelector(selectCart);
  const giftCardFlow = useSelector(selectPackageFlow);
  const shippingOptions = useSelector(selectShippingOptions);
  const purchaseDetails = useSelector(selectPurchaseDetails);
  const checkoutData = useSelector(selectCheckoutData);
  const packageFlow = useSelector(selectPackageFlow);
  const checkoutVariant = useSelector(selectCheckoutVariant);

  const isWideScreen = useMediaQuery('(min-width: 910px)');
  const [layout, setLayout] = useState<CheckoutLayout>('single-pane');

  useEffect(() => {
    setLayout(isWideScreen ? 'two-pane' : 'single-pane');
  }, [isWideScreen]);

  /*
  // INIT
  */

  const customBookProps = useMemo(() => Object.entries(CUSTOM_BOOK_PARAMS).reduce((acc, [key, paramNames]) => {
    // Try entry format first, then direct format
    const value = searchParams.get(paramNames.entry) || searchParams.get(paramNames.direct) || '';
    acc[key] = value;
    return acc;
  }, {} as Record<string, string>), [searchParams]);

  const {
    email,
    byLineName,
    namePlateName,
    message,
    bookTitle: rawBookTitle,
  } = customBookProps;

  const { bookTitle, storyId } = useMemo(() => {
    if (!rawBookTitle) {
      return { bookTitle: '', storyId: '' };
    }

    const [title, id] = rawBookTitle.split(' - ');
    return {
      bookTitle: title || '',
      storyId: id || '',
    };
  }, [rawBookTitle]);

  useEffect(() => {
    async function init() {
      // Initialize purchaseDetails with URL params
      if (type === 'custom-book') {
        dispatch(setCustomBookPurchaseDetails({
          title: bookTitle,
          byline: byLineName,
          bookplateName: namePlateName,
          dedication: message,
          bookplateStyle: PRODUCT_NAMES.MODERN_BOOKPLATE,
          storyId,
        }));
        dispatch(setCurrentStep(CheckoutStepName.FINALIZE_COVER));
      } else if (type === 'buy-package') {
        dispatch(setPackagePurchaseDetails({
          recipientFirstName: '',
          recipientLastName: '',
          recipientEmail: '',
          giftMessage: '',
          deliveryDate: new Date(),
          giftCardStyle: '',
          gifterName: '',
        }));
        dispatch(setCurrentStep(CheckoutStepName.PURCHASE_TYPE));
      }
      if (!isCheckoutInitialized && !isSuccess) {
        dispatch(initializeCheckout({ type }));
      } else if (isSuccess) {
        dispatch(setCheckoutInitialized(true));

        const params = new URLSearchParams(window.location.search);
        const orderType = params.get('type');
        // const orderId = params.get('order_id');
        const itemCount = params.get('items');

        if (isSuccess && orderType) {
          // Track purchase conversion
          trackCheckoutEvent('purchase_complete', {
            type: orderType as 'buy-package' | 'custom-book',
            item_count: parseInt(itemCount || '0', 10),
            variant: checkoutVariant,
            // Safe to include total here as it's only in analytics, not URL
            // TODO: pass through total
          });
        }
      }

      // Track checkout initialized
    }
    init();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [clientSecret, setClientSecret] = useState<string | null>(null);

  const handleCheckoutSubmit = useCallback(async () => {
    trackCheckoutEvent('checkout_complete', {
      path: packageFlow.purchaseType as PurchaseType,
      cart_value: cart.total,
      items: cart.items.map((item) => item.name),
      variant: checkoutVariant,
    });

    let prefilledEmail;
    if (purchaseDetails && isCustomBookPurchase(purchaseDetails)) {
      prefilledEmail = email;
    } else if (purchaseDetails && !isCustomBookPurchase(purchaseDetails)) {
      prefilledEmail = checkoutData?.purchaserEmail;
    }

    const fbData = {
      fbc: getFacebookClickCookie(),
      fbp: getFacebookPixelCookie(),
    };

    try {
      // Instead of redirecting, create a checkout session and get the client secret
      const response = await dispatch(submitCheckout({
        email: prefilledEmail || '',
        cart,
        shippingOptions,
        fbConversionData: fbData as FacebookConversionData,
        embedded: true, // Add this flag
      })).unwrap();

      if (typeof response === 'object' && 'clientSecret' in response) {
        setClientSecret(response.clientSecret);
      } else {
        throw new Error('Invalid response format');
      }
    } catch (error) {
      console.error('Error creating checkout session:', error);
    }
  }, [type, giftCardFlow, cart, purchaseDetails, dispatch, shippingOptions, email]);

  const [isCheckoutFormValid, setIsCheckoutFormValid] = useState(false);

  /*
//   ///////////////////////
//   All the steps
//   ///////////////////////
//   */

  const [isFinalizeCoverFormValid, setIsFinalizeCoverFormValid] = useState(false);

  const memoizedFinalizeCoverStep: CheckoutStepType = useMemo(() => ({
    name: CheckoutStepName.FINALIZE_COVER,
    title: 'Finalize Cover',
    children: (
      <FinalizeCover
        {...customBookProps}
        bookTitle={bookTitle}
        byLineName={byLineName}
        onIsFormValid={setIsFinalizeCoverFormValid}
      />
    ),
    isNextDisabled: !isFinalizeCoverFormValid,
    isLastStep: false,
    showNextButton: true,
  }), [customBookProps, bookTitle, byLineName, isFinalizeCoverFormValid]);

  const [isPersonalizeInsideFormValid, setIsPersonalizeInsideFormValid] = useState(false);

  const memoizedPersonalizeInsideStep: CheckoutStepType = useMemo(() => ({
    name: CheckoutStepName.PERSONALIZE_INSIDE,
    title: 'Personalize Inside',
    children: (
      <PersonalizeInside
        bookplateName={namePlateName}
        dedicationMessage={message}
        onIsFormValid={setIsPersonalizeInsideFormValid}
      />
    ),
    isNextDisabled: !isPersonalizeInsideFormValid,
    isLastStep: false,
    showNextButton: true,
  }), [namePlateName, message, isPersonalizeInsideFormValid]);

  useEffect(() => {
    console.log('packageFlow', packageFlow);
  }, [packageFlow]);

  const memoizedWhoAreBuyingForStep: CheckoutStepType = useMemo(() => ({
    name: CheckoutStepName.PURCHASE_TYPE,
    title: 'Who are you buying for?',
    children: (
      <WhoAreYouBuyingFor />
    ),
    isLastStep: false,
    showNextButton: false,
  }), [dispatch]);

  const memoizedWhoWillCreateStep: CheckoutStepType = useMemo(() => ({
    name: CheckoutStepName.WHO_WILL_CREATE,
    title: 'Who will be using Wanderly?',
    children: (
      <WhoWillCreate />
    ),
    isLastStep: false,
    showNextButton: false,
  }), [dispatch]);

  const memoizedWanderlyReasonStep: CheckoutStepType = useMemo(() => ({
    name: CheckoutStepName.WANDERLY_REASON,
    title: 'What interests you the most about Wanderly?',
    children: (
      <WanderlyReason />
    ),
    isLastStep: false,
    showNextButton: false,
  }), [dispatch]);

  const memoizedEmailStep: CheckoutStepType = useMemo(() => ({
    name: CheckoutStepName.EMAIL,
    title: 'Your email address is all we need to begin. 💌',
    children: (
      <EmailPrompt />
    ),
    isLastStep: true,
    showNextButton: false,
  }), [isWideScreen]);

  const [isRecipientAndGifterInfoFormValid, setIsRecipientAndGifterInfoFormValid] = useState(false);

  const memoizedRecipientAndGifterInfoStep: CheckoutStepType = useMemo(() => ({
    name: CheckoutStepName.GIFT_DELIVERY,
    title: 'Gift Delivery Details',
    children: (
      <GiftDeliveryInfo
        onIsFormValid={setIsRecipientAndGifterInfoFormValid}
      />
    ),
    isNextDisabled: !isRecipientAndGifterInfoFormValid,
    isLastStep: false,
    showNextButton: true,
  }), [isRecipientAndGifterInfoFormValid]);

  const [isCustomizeGiftFormValid, setIsCustomizeGiftFormValid] = useState(false);

  const memoizedCustomizeGiftStep: CheckoutStepType = useMemo(() => ({
    name: CheckoutStepName.CUSTOMIZE_GIFT,
    title: 'Customize Gift Message',
    children: (
      <CustomizeGift
        onIsFormValid={setIsCustomizeGiftFormValid}
      />
    ),
    isNextDisabled: !isCustomizeGiftFormValid,
    isLastStep: false,
    showNextButton: true,
  }), [isCustomizeGiftFormValid]);

  const memoizedSelectPackageStep: CheckoutStepType = useMemo(() => ({
    name: CheckoutStepName.SELECT_PACKAGE,
    title: 'Select Package',
    children: (
      <SelectPackage />
    ),
    isLastStep: false,
    showNextButton: true,
  }), []);

  const memoizedOrderSummaryStep: CheckoutStepType = useMemo(() => ({
    name: CheckoutStepName.ORDER_SUMMARY,
    title: 'Order Summary',
    children: (
      <OrderSummary
        orderSummaryType={type as 'buy-package' | 'custom-book'}
        isGoToCheckoutDisabled={!isCheckoutFormValid}
        onConfirm={handleCheckoutSubmit}
      />
    ),
    isLastStep: true,
    showNextButton: false,
  }), [type, handleCheckoutSubmit, isCheckoutFormValid, giftCardFlow]);

  // for making sure checkout form is valid
  useEffect(() => {
    if (packageFlow.purchaseType === PurchaseType.BOOK_ORDER) {
      setIsCheckoutFormValid(isFinalizeCoverFormValid && isPersonalizeInsideFormValid);
    } else if (packageFlow.purchaseType === PurchaseType.GIFT) {
      setIsCheckoutFormValid(isRecipientAndGifterInfoFormValid && isCustomizeGiftFormValid);
    } else {
      setIsCheckoutFormValid(true);
    }
  }, [isFinalizeCoverFormValid, isPersonalizeInsideFormValid, isRecipientAndGifterInfoFormValid, isCustomizeGiftFormValid, packageFlow.purchaseType]);

  // Add new step components
  const memoizedInitialValuePropStep: CheckoutStepType = useMemo(() => ({
    name: CheckoutStepName.INITIAL_VALUE_PROP,
    children: (
      <InitialValueProp />
    ),
  }), [packageFlow.purchaseType]);

  // Create value prop steps for each reason
  const reasonValuePropSteps = useMemo(() => {
    const steps: Record<string, CheckoutStepType> = {
      [CheckoutStepName.PERSONALIZED_STORIES_VALUE_PROP]: {
        name: CheckoutStepName.PERSONALIZED_STORIES_VALUE_PROP,
        children: <ReasonValueProp reason={WanderlyReasonEnum.PERSONALIZED_STORIES} />,
      },
      [CheckoutStepName.CREATIVITY_CONFIDENCE_VALUE_PROP]: {
        name: CheckoutStepName.CREATIVITY_CONFIDENCE_VALUE_PROP,
        children: <ReasonValueProp reason={WanderlyReasonEnum.CREATIVITY_CONFIDENCE} />,
      },
      [CheckoutStepName.CONNECT_WITH_VALUE_PROP]: {
        name: CheckoutStepName.CONNECT_WITH_VALUE_PROP,
        children: <ReasonValueProp reason={WanderlyReasonEnum.CONNECT_WITH} />,
      },
      [CheckoutStepName.CUSTOM_ILLUSTRATIONS_VALUE_PROP]: {
        name: CheckoutStepName.CUSTOM_ILLUSTRATIONS_VALUE_PROP,
        children: <ReasonValueProp reason={WanderlyReasonEnum.CUSTOM_ILLUSTRATIONS} />,
      },
      [CheckoutStepName.READING_PRACTICE_VALUE_PROP]: {
        name: CheckoutStepName.READING_PRACTICE_VALUE_PROP,
        children: <ReasonValueProp reason={WanderlyReasonEnum.READING_PRACTICE} />,
      },
      [CheckoutStepName.PERSONALIZED_BOOKS_VALUE_PROP]: {
        name: CheckoutStepName.PERSONALIZED_BOOKS_VALUE_PROP,
        children: <ReasonValueProp reason={WanderlyReasonEnum.PERSONALIZED_BOOKS} />,
      },
    };
    return steps;
  }, []);

  // Update stepComponents to include new steps
  const stepComponents = useMemo(() => ({
    [CheckoutStepName.FINALIZE_COVER]: memoizedFinalizeCoverStep,
    [CheckoutStepName.PERSONALIZE_INSIDE]: memoizedPersonalizeInsideStep,
    [CheckoutStepName.PURCHASE_TYPE]: memoizedWhoAreBuyingForStep,
    [CheckoutStepName.WHO_WILL_CREATE]: memoizedWhoWillCreateStep,
    [CheckoutStepName.EMAIL]: memoizedEmailStep,
    [CheckoutStepName.GIFT_DELIVERY]: memoizedRecipientAndGifterInfoStep,
    [CheckoutStepName.CUSTOMIZE_GIFT]: memoizedCustomizeGiftStep,
    [CheckoutStepName.SELECT_PACKAGE]: memoizedSelectPackageStep,
    [CheckoutStepName.ORDER_SUMMARY]: memoizedOrderSummaryStep,
    [CheckoutStepName.WANDERLY_REASON]: memoizedWanderlyReasonStep,
    [CheckoutStepName.INITIAL_VALUE_PROP]: memoizedInitialValuePropStep,
    [CheckoutStepName.REASON_VALUE_PROPS]: reasonValuePropSteps[CheckoutStepName.REASON_VALUE_PROPS],
    [CheckoutStepName.CREATIVITY_CONFIDENCE_VALUE_PROP]: reasonValuePropSteps[CheckoutStepName.CREATIVITY_CONFIDENCE_VALUE_PROP],
    [CheckoutStepName.PERSONALIZED_STORIES_VALUE_PROP]: reasonValuePropSteps[CheckoutStepName.PERSONALIZED_STORIES_VALUE_PROP],
    [CheckoutStepName.CONNECT_WITH_VALUE_PROP]: reasonValuePropSteps[CheckoutStepName.CONNECT_WITH_VALUE_PROP],
    [CheckoutStepName.CUSTOM_ILLUSTRATIONS_VALUE_PROP]: reasonValuePropSteps[CheckoutStepName.CUSTOM_ILLUSTRATIONS_VALUE_PROP],
    [CheckoutStepName.READING_PRACTICE_VALUE_PROP]: reasonValuePropSteps[CheckoutStepName.READING_PRACTICE_VALUE_PROP],
    [CheckoutStepName.PERSONALIZED_BOOKS_VALUE_PROP]: reasonValuePropSteps[CheckoutStepName.PERSONALIZED_BOOKS_VALUE_PROP],
  }), [
    memoizedFinalizeCoverStep,
    memoizedPersonalizeInsideStep,
    memoizedWhoAreBuyingForStep,
    memoizedWhoWillCreateStep,
    memoizedEmailStep,
    memoizedRecipientAndGifterInfoStep,
    memoizedCustomizeGiftStep,
    memoizedSelectPackageStep,
    memoizedOrderSummaryStep,
    memoizedWanderlyReasonStep,
    memoizedInitialValuePropStep,
    reasonValuePropSteps,
  ]);

  // Track path changes
  useEffect(() => {
    if (packageFlow.purchaseType === PurchaseType.BOOK_ORDER) {
      trackCheckoutEvent('checkout_path_selected', {
        path: PurchaseType.BOOK_ORDER,
        variant: checkoutVariant,
      });
    } else if (packageFlow.purchaseType === PurchaseType.SELF) {
      trackCheckoutEvent('checkout_path_selected', {
        path: PurchaseType.SELF,
        variant: checkoutVariant,
      });
    } else if (packageFlow.purchaseType === PurchaseType.GIFT) {
      trackCheckoutEvent('checkout_path_selected', {
        path: PurchaseType.GIFT,
        variant: checkoutVariant,
      });
    }
  }, [type, packageFlow.purchaseType, checkoutVariant]);

  // Function to handle closing the modal
  const closeModal = () => {
    setClientSecret(null);
  };

  return (
    <NoAuthPageWrapper>
      <SeoMeta
        {...PAGE_META.checkout}
        canonicalUrl="/checkout"
      />
      <div className="bg-white flex flex-col h-screen relative overflow-hidden">
        {/* Background container */}
        <div className="absolute inset-0 overflow-hidden pointer-events-none">
          {/* Yellow Organic Blob */}
          <img
            src={yellowOrganicBlob}
            alt=""
            className="absolute top-0 left-2/3 w-3/4 h-auto transform -translate-x-1/2 -translate-y-1/4"
          />

          {/* Background SVGs */}
          <img
            src={yellowOrganicClosedLine}
            alt=""
            className="absolute top-0 left-0 w-1/4 h-auto opacity-50 transform -translate-x-1/4 -translate-y-1/4"
          />
          <img
            src={yellowOrganicClosedLine}
            alt=""
            className="absolute bottom-0 right-0 w-1/4 h-auto opacity-50 transform translate-x-1/4 translate-y-1/4 rotate-180"
          />
          {/* New Color Splash Image */}
          <img
            src={`${imageBaseURL}misc/checkout/wanderly-color-splash-1_1024w.webp`}
            alt=""
            className="absolute left-0 top-1/2 h-1/2 w-auto opacity-40 transform -translate-x-1/4 -translate-y-1/2 max-w-full"
          />
        </div>

        <CheckoutHeader showLogo="" />
        <main className="flex-1 p-6 overflow-hidden z-10">
          <div className={`h-full mx-auto ${layout === 'single-pane' ? 'max-w-lg' : 'max-w-5xl'}`}>
            <div className="flex flex-row gap-6 h-full">
              {isSuccess ? (
                <div className="flex-1 bg-white p-6 rounded-lg shadow flex items-center justify-center">
                  <div className="text-center md:text-left md:flex md:items-center md:gap-12 max-w-4xl w-full">
                    {/* Image container - added min-width to prevent squishing */}
                    <div className="order-1 md:order-1 relative mb-8 md:mb-0 md:min-w-[320px] flex justify-center md:justify-start">
                      <div className="relative">
                        <img
                          src={logo512}
                          alt="Wanderly Logo"
                          className="w-48 h-48 md:w-80 md:h-80 rounded-lg"
                        />
                        <img
                          src={yayWebster}
                          alt=""
                          className="absolute bottom-0 left-0 w-32 h-32 md:w-48 md:h-48 transform translate-y-1/4 -translate-x-1/4"
                        />
                      </div>
                    </div>

                    {/* Text content - below images for mobile, right side for desktop */}
                    <div className="order-2 md:order-2">
                      <h1 className="text-3xl mb-4">Thank you!</h1>
                      <p className="mb-6">
                        Your receipt will be sent to your email address in just a moment.
                      </p>
                      <button
                        onClick={() => {
                          window.location.href = 'https://wander.ly';
                        }}
                        className="bg-accent1 text-white px-6 py-2 rounded-lg hover:bg-accent1-dark"
                      >
                        Return Home
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  {layout === 'two-pane' && (
                    <div className="flex-1 bg-white p-6 rounded-lg shadow">
                      <Suspense fallback={(
                        <div className="flex items-center justify-center h-full">
                          <Loader
                            filledColor={colors.accent1.DEFAULT}
                            width="4em"
                            height="4em"
                          />
                        </div>
                      )}
                      >
                        <ImageCarousel images={GALLERY_BOOK_IMAGES} showThumbnails />
                      </Suspense>
                    </div>
                  )}
                  <div className={`flex-1 bg-white p-6 rounded-lg shadow flex flex-col ${layout === 'single-pane' ? 'w-full' : ''}`}>
                    <div className="flex-shrink-0">
                      <CheckoutQuickSummary />
                    </div>
                    <div className="flex-grow overflow-hidden">
                      <CheckoutWizard
                        stepComponents={stepComponents}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </main>

        {/* Loading Overlay */}
        {!isCheckoutInitialized && !isSuccess && (
          <div className="fixed inset-0 z-50 flex items-center justify-center">
            <div className="absolute inset-0 bg-white opacity-70" />
            <div className="z-10 bg-white p-8 rounded-lg shadow-lg flex flex-col items-center">
              <Loader
                filledColor={colors.accent1.DEFAULT}
                width="4em"
                height="4em"
              />
              <p className="mt-4 text-lg">Loading checkout...</p>
            </div>
          </div>
        )}

        {clientSecret && (
          <div className="fixed inset-0 z-50 flex items-center justify-center">
            {/* Overlay */}
            <div
              className="absolute inset-0 bg-black opacity-50"
              onClick={closeModal}
              onKeyDown={closeModal}
              role="button"
              tabIndex={0}
              aria-label="Close checkout modal overlay"
            />
            {/* Modal Container */}
            <div className="relative z-10 bg-white p-4 rounded-lg w-full max-w-2xl max-h-[90vh] overflow-y-auto">
              {/* Close Button */}
              <AnButton
                onClick={closeModal}
                className="absolute right-3 top-3"
                iconRight={AnCrossIcon}
                variant="text"
                color="text-dark"
                iconProps={{
                  size: 'xl',
                }}
              />

              <EmbeddedCheckoutProvider
                stripe={stripePromise}
                options={{ clientSecret }}
              >
                <EmbeddedCheckout />
              </EmbeddedCheckoutProvider>
            </div>
          </div>
        )}
      </div>
    </NoAuthPageWrapper>
  );
}
