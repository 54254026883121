import React, { useEffect } from 'react';
import AnButton from '@components/AnButton';
import AnEditPencilIcon from '@components/AnIcon/icons/AnEditPencilIcon';
import { useSelector } from 'react-redux';
import {
  selectCart, selectCheckoutData, selectIsCheckoutSessionLoading, selectPackageFlow, selectPurchaseDetails,
} from '@store/checkoutSlice';
import {
  CustomBookPurchase,
  PRODUCT_NAMES,
  PackagePurchase,
  PackageProductName,
  PurchaseType,
  CheckoutStepName,
} from './types';
import useCheckoutNavigation from './hooks/useCheckoutNavigation';

interface OrderSummaryProps {
  orderSummaryType: 'custom-book' | 'buy-package';
  isGoToCheckoutDisabled: boolean;
  onConfirm: () => void;
}

function ErrorMessage({
  message,
  scrollTo,
  goToStep,
}: {
  message: string;
  scrollTo: CheckoutStepName;
  goToStep: (step: CheckoutStepName) => void;
}) {
  return (
    <button
      className="text-error cursor-pointer hover:underline font-italic"
      onClick={() => goToStep(scrollTo)}
      type="button"
    >
      {message}
    </button>
  );
}

export default function OrderSummary({
  orderSummaryType,
  isGoToCheckoutDisabled,
  onConfirm,
}: OrderSummaryProps) {
  const purchaseDetails = useSelector(selectPurchaseDetails);
  const checkoutData = useSelector(selectCheckoutData);
  const checkoutSessionLoading = useSelector(selectIsCheckoutSessionLoading);
  const cart = useSelector(selectCart);
  const packageFlow = useSelector(selectPackageFlow);
  const { goToStep } = useCheckoutNavigation();

  useEffect(() => {
    console.log('purchaseDetails', purchaseDetails);
  }, [purchaseDetails]);

  const renderCustomBookOrderSummary = () => {
    const customBookPurchase = purchaseDetails as CustomBookPurchase;
    const customBookItem = cart.items.find((item) => item.name === PRODUCT_NAMES.CUSTOM_BOOK);
    const customBookAmountString = customBookItem ? (customBookItem.amount).toFixed(2) : '0.00';
    const coverDetails = cart.items.find((item) => item.name === PRODUCT_NAMES.PREMIUM_COVER || item.name === PRODUCT_NAMES.STANDARD_COVER);
    const coverAmountString = coverDetails ? (coverDetails.amount).toFixed(2) : '0.00';
    const titlePageDedication = customBookPurchase.dedication === '' ? 'No dedication' : customBookPurchase.dedication;
    const dedicationStyle = customBookPurchase.dedication === '' ? 'italic text-gray-300' : '';

    return (
      <div className="mt-4">
        <div className="flex justify-between items-center mb-6">
          <p className="text-xl">{PRODUCT_NAMES.CUSTOM_BOOK}</p>
          <span className="text-lg font-medium">
            + $
            {customBookAmountString}
          </span>
        </div>

        {/* Light horizontal line */}
        <hr className="border-t border-gray-200 my-4" />

        {/* Cover Details */}
        <div className="mb-6">
          <div className="flex justify-between items-center mb-2">
            <p className="text-xl">Cover Details</p>
            <div
              onClick={() => goToStep(CheckoutStepName.FINALIZE_COVER)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  goToStep(CheckoutStepName.FINALIZE_COVER);
                }
              }}
              className="cursor-pointer"
              role="button"
              tabIndex={0}
            >
              <AnEditPencilIcon
                size="md"
                fillColorClass="fill-gray-300"
              />
            </div>
          </div>
          <div className="pr-8">
            <p>
              {customBookPurchase.title
                || <ErrorMessage message="Please enter a book title" scrollTo={CheckoutStepName.FINALIZE_COVER} goToStep={goToStep} />}
            </p>
            <p className="text-sm text-gray-500 mb-2">
              by
              {' '}
              {customBookPurchase.byline
                || <ErrorMessage message="Please enter author name" scrollTo={CheckoutStepName.FINALIZE_COVER} goToStep={goToStep} />}
            </p>
          </div>
          <div className="flex justify-between items-center mb-2">
            <p className="text-md">{coverDetails ? coverDetails.name : 'Cover Selection'}</p>
            <span className="text-md font-medium">
              + $
              {coverAmountString}
            </span>
          </div>
        </div>

        {/* Light horizontal line */}
        <hr className="border-t border-gray-200 my-4" />

        {/* Personalize Inside Details */}
        <div className="flex justify-between items-start mb-2">
          <div className="flex-grow pr-4">
            <p className="text-xl mb-2">Personalize Inside</p>
            <div>
              <div className="mb-2">
                <p className="text-sm text-gray-500">
                  {customBookPurchase.bookplateStyle
                    || <ErrorMessage message="Please select a bookplate style" scrollTo={CheckoutStepName.PERSONALIZE_INSIDE} goToStep={goToStep} />}
                </p>
                <p>
                  {customBookPurchase.bookplateName
                    || <ErrorMessage message="Please enter a name for the bookplate" scrollTo={CheckoutStepName.PERSONALIZE_INSIDE} goToStep={goToStep} />}
                </p>
              </div>
              <div className="mb-2">
                <p className="text-sm text-gray-500">
                  Title page dedication
                </p>
                <p className={`${dedicationStyle} whitespace-pre-wrap`}>
                  {titlePageDedication}
                </p>
              </div>
            </div>
          </div>
          <div
            onClick={() => goToStep(CheckoutStepName.PERSONALIZE_INSIDE)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                goToStep(CheckoutStepName.PERSONALIZE_INSIDE);
              }
            }}
            className="cursor-pointer flex-shrink-0"
            role="button"
            tabIndex={0}
          >
            <AnEditPencilIcon
              size="md"
              fillColorClass="fill-gray-300"
            />
          </div>
        </div>
      </div>
    );
  };

  const renderPackageOrderSummary = () => {
    const packagePurchase = purchaseDetails as PackagePurchase;
    const packageItem = cart.items.find((item) => item.name as PackageProductName);
    const packageAmountString = packageItem ? (packageItem.amount).toFixed(2) : '0.00';

    return (
      <div className="mt-4">
        {/* Package Details */}
        <div className="mb-6">
          <div className="flex justify-between items-center mb-2">
            <p className="text-xl">Wanderly Package</p>
            <div
              onClick={() => goToStep(CheckoutStepName.SELECT_PACKAGE)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  goToStep(CheckoutStepName.SELECT_PACKAGE);
                }
              }}
              className="cursor-pointer"
              role="button"
              tabIndex={0}
            >
              <AnEditPencilIcon
                size="md"
                fillColorClass="fill-gray-300"
              />
            </div>
          </div>
          <div className="flex justify-between items-start mb-2">
            <div className="flex-grow pr-4">
              <p className="text-md font-medium">{packageItem?.name}</p>
              <p className="text-sm text-gray-500 mt-1">
                {packageItem?.description}
              </p>
            </div>
            <span className="text-md font-medium whitespace-nowrap">
              + $
              {packageAmountString}
            </span>
          </div>
        </div>

        {packageFlow.purchaseType === PurchaseType.GIFT && (
          <>
            {/* Light horizontal line */}
            <hr className="border-t border-gray-200 my-4" />

            {/* Delivery Info */}
            <div className="mb-6">
              <div className="flex justify-between items-center mb-2">
                <p className="text-xl">Deliver To</p>
                <div
                  onClick={() => goToStep(CheckoutStepName.GIFT_DELIVERY)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      goToStep(CheckoutStepName.GIFT_DELIVERY);
                    }
                  }}
                  className="cursor-pointer"
                  role="button"
                  tabIndex={0}
                >
                  <AnEditPencilIcon
                    size="md"
                    fillColorClass="fill-gray-300"
                  />
                </div>
              </div>
              <div className="pr-8">
                <p>
                  {(packagePurchase.recipientFirstName && packagePurchase.recipientLastName)
                    ? `${packagePurchase.recipientFirstName} ${packagePurchase.recipientLastName}`
                    : (
                      <ErrorMessage
                        message="Please enter recipient's full name"
                        scrollTo={CheckoutStepName.GIFT_DELIVERY}
                        goToStep={goToStep}
                      />
                    )}
                </p>
                <p className="text-sm text-gray-500 mb-2">
                  {packagePurchase.recipientEmail
                    || (
                      <ErrorMessage
                        message="Please enter recipient's email"
                        scrollTo={CheckoutStepName.GIFT_DELIVERY}
                        goToStep={goToStep}
                      />
                    )}
                </p>
              </div>
              <div className="mb-2">
                <p className="text-sm text-gray-500">Delivery Date</p>
                <p>
                  {packagePurchase.deliveryDate
                    ? (() => {
                      const today = new Date();
                      const deliveryDate = new Date(packagePurchase.deliveryDate as any);

                      if (
                        deliveryDate.getDate() === today.getDate()
                        && deliveryDate.getMonth() === today.getMonth()
                        && deliveryDate.getFullYear() === today.getFullYear()
                      ) {
                        return 'Today';
                      }
                      return deliveryDate.toLocaleDateString();
                    })()
                    : 'Not specified'}
                </p>
              </div>
            </div>

            {/* Light horizontal line */}
            <hr className="border-t border-gray-200 my-4" />

            {/* Deliver From Details - New Section */}
            <div className="mb-6">
              <div className="flex justify-between items-center mb-2">
                <p className="text-xl">Deliver From</p>
                <div
                  onClick={() => goToStep(CheckoutStepName.EMAIL)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      goToStep(CheckoutStepName.EMAIL);
                    }
                  }}
                  className="cursor-pointer"
                  role="button"
                  tabIndex={0}
                >
                  <AnEditPencilIcon
                    size="md"
                    fillColorClass="fill-gray-300"
                  />
                </div>
              </div>
              <div className="pr-8">
                <p>
                  {(packagePurchase.gifterName)
                    ? packagePurchase.gifterName
                    : (
                      <ErrorMessage
                        message="Please enter your name"
                        scrollTo={CheckoutStepName.CUSTOMIZE_GIFT}
                        goToStep={goToStep}
                      />
                    )}
                </p>
                <p className="text-sm text-gray-500 mb-2">
                  {checkoutData?.purchaserEmail
                    ? checkoutData?.purchaserEmail
                    : (
                      <ErrorMessage
                        message="Please enter your email"
                        scrollTo={CheckoutStepName.CUSTOMIZE_GIFT}
                        goToStep={goToStep}
                      />
                    )}
                </p>
              </div>
            </div>

            {/* Light horizontal line */}
            <hr className="border-t border-gray-200 my-4" />

            {/* Gift Customization - Modified to remove gifter info */}
            <div className="flex justify-between items-start mb-2">
              <div className="flex-grow pr-4">
                <p className="text-xl mb-2">Gift Customization</p>
                <div>
                  <div className="mb-2">
                    <p className="text-sm text-gray-500">Gift Card Style</p>
                    <p>
                      {packagePurchase.giftCardStyle
                        || (
                          <ErrorMessage
                            message="Please select a gift card style"
                            scrollTo={CheckoutStepName.CUSTOMIZE_GIFT}
                            goToStep={goToStep}
                          />
                        )}
                    </p>
                  </div>
                  <div className="mb-2">
                    <p className="text-sm text-gray-500">Gift Message</p>
                    <p className="whitespace-pre-wrap">
                      {packagePurchase.giftMessage
                        || (
                          <ErrorMessage
                            message="Please add a gift message"
                            scrollTo={CheckoutStepName.CUSTOMIZE_GIFT}
                            goToStep={goToStep}
                          />
                        )}
                    </p>
                  </div>
                </div>
              </div>
              <div
                onClick={() => goToStep(CheckoutStepName.CUSTOMIZE_GIFT)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    goToStep(CheckoutStepName.CUSTOMIZE_GIFT);
                  }
                }}
                className="cursor-pointer flex-shrink-0"
                role="button"
                tabIndex={0}
              >
                <AnEditPencilIcon
                  size="md"
                  fillColorClass="fill-gray-300"
                />
              </div>
            </div>
          </>
        )}
      </div>
    );
  };

  const renderPurchaseDetails = () => {
    if (orderSummaryType === 'custom-book' && purchaseDetails) {
      return renderCustomBookOrderSummary();
    } if (orderSummaryType === 'buy-package' && purchaseDetails) {
      return renderPackageOrderSummary();
    }
    return null;
  };

  return (
    <>
      <div>
        {renderPurchaseDetails()}
      </div>
      <div className="mt-6 flex justify-between items-center">
        <div className="flex-grow mr-4">
          <div className="text-2xl font-bold">
            Total: $
            {(cart.total).toFixed(2)}
          </div>
          <div className="text-sm text-gray-500">
            {orderSummaryType === 'custom-book'
              ? 'Taxes and shipping calculated at checkout'
              : 'Taxes calculated at checkout'}
          </div>
        </div>
        <div className="flex-shrink-0">
          <AnButton onClick={() => onConfirm()} disabled={checkoutSessionLoading || isGoToCheckoutDisabled}>Go to Checkout</AnButton>
          {checkoutSessionLoading && <p className="text-color-gray-500 text-xs mt-2">Loading your cart...</p>}
        </div>
      </div>
    </>
  );
}
