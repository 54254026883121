import {
  CheckoutStepName,
  PackageFlowType,
  PurchaseType,
  WanderlyReason,
} from '../types';

// Update the mapping function to map multiple reasons to the same step
const reasonToStepName = (reason: WanderlyReason): CheckoutStepName => {
  switch (reason) {
    case WanderlyReason.CREATIVITY_CONFIDENCE:
    case WanderlyReason.PERSONALIZED_STORIES:
    case WanderlyReason.CONNECT_WITH:
      return CheckoutStepName.PERSONALIZED_STORIES_VALUE_PROP;
    case WanderlyReason.CUSTOM_ILLUSTRATIONS:
      return CheckoutStepName.CUSTOM_ILLUSTRATIONS_VALUE_PROP;
    case WanderlyReason.READING_PRACTICE:
      return CheckoutStepName.READING_PRACTICE_VALUE_PROP;
    case WanderlyReason.PERSONALIZED_BOOKS:
      return CheckoutStepName.PERSONALIZED_BOOKS_VALUE_PROP;
    default:
      throw new Error(`Unknown reason: ${reason}`);
  }
};

// Helper function to get the canonical reason for grouped reasons
const getCanonicalReason = (reason: WanderlyReason): WanderlyReason => {
  switch (reason) {
    case WanderlyReason.CREATIVITY_CONFIDENCE:
    case WanderlyReason.CONNECT_WITH:
      return WanderlyReason.PERSONALIZED_STORIES;
    default:
      return reason;
  }
};

export default function getNextStep(currentStep: CheckoutStepName | null, flowData: PackageFlowType & { checkoutVariant: 'control' | 'test' | null }): CheckoutStepName | null {
  const isTestVariant = flowData.checkoutVariant === 'test';
  let nextStep: CheckoutStepName | null = null;

  // Helper function to get the next reason step
  const getNextReasonStep = (currentReason: WanderlyReason | null): CheckoutStepName | null => {
    // Get unique canonical reasons
    const canonicalReasons = flowData.reasons
      .map(getCanonicalReason)
      .filter((value, index, self) => self.indexOf(value) === index);

    const currentCanonicalReason = currentReason ? getCanonicalReason(currentReason) : null;
    const reasonIndex = currentCanonicalReason
      ? canonicalReasons.indexOf(currentCanonicalReason)
      : -1;

    // If we have more reasons to show, return the next reason's step
    if (reasonIndex < canonicalReasons.length - 1) {
      const nextReason = canonicalReasons[reasonIndex + 1];
      return reasonToStepName(nextReason);
    }

    return CheckoutStepName.EMAIL;
  };

  switch (currentStep) {
    case CheckoutStepName.PURCHASE_TYPE:
      if (isTestVariant) {
        nextStep = CheckoutStepName.INITIAL_VALUE_PROP;
      } else {
        nextStep = flowData.purchaseType === PurchaseType.GIFT
          ? CheckoutStepName.WHO_WILL_CREATE
          : CheckoutStepName.WANDERLY_REASON;
      }
      break;

    case CheckoutStepName.INITIAL_VALUE_PROP:
      nextStep = flowData.purchaseType === PurchaseType.GIFT
        ? CheckoutStepName.WHO_WILL_CREATE
        : CheckoutStepName.WANDERLY_REASON;
      break;

    case CheckoutStepName.WHO_WILL_CREATE:
      nextStep = CheckoutStepName.WANDERLY_REASON;
      break;

    case CheckoutStepName.WANDERLY_REASON:
      if (isTestVariant && flowData.reasons.length > 0) {
        // Start with the first reason's value prop
        nextStep = reasonToStepName(flowData.reasons[0]);
      } else {
        nextStep = CheckoutStepName.EMAIL;
      }
      break;

    // Consolidate the value prop step handling
    case CheckoutStepName.PERSONALIZED_STORIES_VALUE_PROP:
      nextStep = getNextReasonStep(WanderlyReason.PERSONALIZED_STORIES);
      break;
    case CheckoutStepName.CUSTOM_ILLUSTRATIONS_VALUE_PROP:
      nextStep = getNextReasonStep(WanderlyReason.CUSTOM_ILLUSTRATIONS);
      break;
    case CheckoutStepName.READING_PRACTICE_VALUE_PROP:
      nextStep = getNextReasonStep(WanderlyReason.READING_PRACTICE);
      break;
    case CheckoutStepName.PERSONALIZED_BOOKS_VALUE_PROP:
      nextStep = getNextReasonStep(WanderlyReason.PERSONALIZED_BOOKS);
      break;

    case CheckoutStepName.EMAIL:
      nextStep = CheckoutStepName.SELECT_PACKAGE;
      break;

    case CheckoutStepName.FINALIZE_COVER:
      nextStep = CheckoutStepName.PERSONALIZE_INSIDE;
      break;

    case CheckoutStepName.PERSONALIZE_INSIDE:
      nextStep = CheckoutStepName.ORDER_SUMMARY;
      break;

    case CheckoutStepName.SELECT_PACKAGE:
      nextStep = flowData.purchaseType === PurchaseType.GIFT
        ? CheckoutStepName.GIFT_DELIVERY
        : CheckoutStepName.ORDER_SUMMARY;
      break;

    case CheckoutStepName.GIFT_DELIVERY:
      nextStep = CheckoutStepName.CUSTOMIZE_GIFT;
      break;

    case CheckoutStepName.CUSTOMIZE_GIFT:
      nextStep = CheckoutStepName.ORDER_SUMMARY;
      break;

    default:
      nextStep = null;
      break;
  }

  console.log('nextStep', nextStep);

  return nextStep;
}
