import AnButton from '@components/AnButton';
import { selectPackageFlow } from '@store/checkoutSlice';
import { useSelector } from 'react-redux';
import { PackageFlowType, PurchaseType } from '../types';
import useCheckoutNavigation from '../hooks/useCheckoutNavigation';

export default function WhoAreYouBuyingFor() {
  const { updatePackageFlow, navigateToNext } = useCheckoutNavigation();
  const packageFlow = useSelector(selectPackageFlow);

  const handleSelection = async (value: PackageFlowType) => {
    await updatePackageFlow(value);
    navigateToNext();
  };

  return (
    <div className="flex flex-col space-y-4 my-4 pr-1">
      <AnButton
        color="primary"
        variant="outlined"
        className="bg-white !text-black !font-[Urbanist] w-full"
        onClick={() => handleSelection({
          ...packageFlow,
          purchaseType: PurchaseType.SELF,
          whoWillCreate: 'me',
        })}
      >
        👋 Me and my child
      </AnButton>
      <AnButton
        color="primary"
        variant="contained"
        className="bg-white !text-black !font-[Urbanist] w-full"
        onClick={() => handleSelection({
          ...packageFlow,
          purchaseType: PurchaseType.GIFT,
        })}
      >
        🎁 Someone else
      </AnButton>
    </div>
  );
}
