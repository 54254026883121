import AnButton from '@components/AnButton';
import React, { useMemo } from 'react';
import { selectCheckoutVariant, selectPackageFlow } from '@store/checkoutSlice';
import { useSelector } from 'react-redux';
import trackCheckoutEvent from '@lib/analytics';
import { PurchaseType, WanderlyReason as WanderlyReasonEnum, WhoWillCreate } from '../types';
import useCheckoutNavigation from '../hooks/useCheckoutNavigation';

export default function WanderlyReason() {
  const { updatePackageFlow, navigateToNext, flowData } = useCheckoutNavigation();
  const [selectedReasons, setSelectedReasons] = React.useState<WanderlyReasonEnum[]>(
    flowData.reasons || [],
  );
  const packageFlow = useSelector(selectPackageFlow);
  const checkoutVariant = useSelector(selectCheckoutVariant);

  const reasons = useMemo(() => {
    const baseReasons = Object.values(WanderlyReasonEnum);

    // Filter out CONNECT_WITH for gift purchases unless it's for grandchild or niece/nephew
    return baseReasons.filter((reason) => {
      if (reason === WanderlyReasonEnum.CONNECT_WITH) {
        if (packageFlow.purchaseType === PurchaseType.GIFT) {
          return (
            packageFlow.whoWillCreate === WhoWillCreate.GRANDCHILD
            || packageFlow.whoWillCreate === WhoWillCreate.NIECE_OR_NEPHEW
          );
        }
      }
      return true;
    });
  }, [packageFlow.purchaseType, packageFlow.whoWillCreate]);

  // Add a display text mapper
  const getDisplayText = (reason: WanderlyReasonEnum) => {
    if (reason === WanderlyReasonEnum.CONNECT_WITH) {
      if (packageFlow.purchaseType === PurchaseType.SELF) {
        return '👪 Connect with my child';
      }
      if (packageFlow.purchaseType === PurchaseType.GIFT) {
        if (packageFlow.whoWillCreate === WhoWillCreate.GRANDCHILD) {
          return '👪 Connect with my grandchild';
        }
        if (packageFlow.whoWillCreate === WhoWillCreate.NIECE_OR_NEPHEW) {
          return '👪 Connect with my niece/nephew';
        }
      }
    }
    return reason;
  };

  const isValid = selectedReasons.length > 0;

  const handleSelection = (reason: WanderlyReasonEnum) => {
    const newSelectedReasons = selectedReasons.includes(reason)
      ? selectedReasons.filter((r) => r !== reason)
      : [...selectedReasons, reason];

    setSelectedReasons(newSelectedReasons);
    updatePackageFlow({
      ...flowData,
      reasons: newSelectedReasons,
    });

    trackCheckoutEvent('reason_selected', {
      path: packageFlow.purchaseType || undefined,
      reason,
      variant: checkoutVariant,
    });
  };

  const handleContinue = () => {
    if (isValid) {
      navigateToNext();
    }
  };

  return (
    <div className="flex justify-between flex-col space-y-4 my-4 pr-1">
      <p className="text-sm text-gray-600">
        Select all that apply.
      </p>
      {reasons.map((reason) => (
        <AnButton
          key={reason}
          color="primary"
          variant="toggle"
          active={selectedReasons.includes(reason)}
          className="!font-[Urbanist]"
          onClick={() => handleSelection(reason)}
        >
          {getDisplayText(reason)}
        </AnButton>
      ))}

      <div className="flex flex-col">
        <AnButton
          onClick={handleContinue}
          disabled={!isValid}
        >
          Continue
        </AnButton>
      </div>
    </div>
  );
}
