import React from 'react';
import { CheckoutStepType } from './types';

export default function CheckoutStep({
  title,
  children,
}: CheckoutStepType) {
  return (
    <div className="flex flex-col h-full pt-4">
      {title && <h2>{title}</h2>}

      <div className="flex-grow">
        {children}
      </div>
    </div>
  );
}
