import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@store/store';
import { navigateToNextStep, selectPackageFlow } from '@store/checkoutSlice';
import ValuePropStep from './ValuePropStep';
import { INITIAL_VALUE_PROP_GIFT, INITIAL_VALUE_PROP_SELF } from './valuePropContent';
import { PurchaseType } from '../types';

export default function InitialValueProp() {
  const dispatch = useDispatch<AppDispatch>();
  const packageFlow = useSelector(selectPackageFlow);

  const content = useMemo(() => {
    if (packageFlow.purchaseType === PurchaseType.SELF) {
      return INITIAL_VALUE_PROP_SELF;
    }
    return INITIAL_VALUE_PROP_GIFT;
  }, [packageFlow.purchaseType]);

  const handleContinue = () => {
    dispatch(navigateToNextStep());
  };

  return (
    <ValuePropStep
      content={content}
      onContinue={handleContinue}
    />
  );
}
