import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@store/store';
import {
  selectCurrentStep,
  selectPackageFlow,
  navigateToNextStep,
  navigateBackStep,
  setPackageFlow,
  selectNavigationHistory,
  goToNamedStep,
  selectCheckoutVariant,
} from '@store/checkoutSlice';
import trackCheckoutEvent from '@lib/analytics';
import { CheckoutStepName, PackageFlowType } from '../types';

// Create a typed dispatch hook
export const useCheckoutDispatch = () => useDispatch<AppDispatch>();

export default function useCheckoutNavigation() {
  const dispatch = useCheckoutDispatch();
  const currentStep = useSelector(selectCurrentStep);
  const flowData = useSelector(selectPackageFlow);
  const navigationHistory = useSelector(selectNavigationHistory);
  const checkoutVariant = useSelector(selectCheckoutVariant);

  const navigateToNext = useCallback(() => {
    dispatch(navigateToNextStep());
  }, [dispatch]);

  const handleBack = useCallback(() => {
    dispatch(navigateBackStep());
    trackCheckoutEvent('checkout_step_back', {
      path: flowData.purchaseType || undefined,
      step: currentStep?.toLowerCase().replace(/\s+/g, '_') as CheckoutStepName,
      action: 'back',
      variant: checkoutVariant,
    });
  }, [dispatch, flowData, currentStep, checkoutVariant]);

  const updatePackageFlow = useCallback(async (value: PackageFlowType) => {
    await dispatch(setPackageFlow(value));
  }, [dispatch]);

  const isFirstStep = useMemo(() => navigationHistory.length === 1, [navigationHistory]);
  const isLastStep = useMemo(() => currentStep === CheckoutStepName.ORDER_SUMMARY, [currentStep]);

  const goToStep = useCallback(
    (step: CheckoutStepName) => {
      dispatch(goToNamedStep(step));
    },
    [dispatch],
  );

  return {
    flowData,
    currentStep,
    navigateToNext,
    handleBack,
    updatePackageFlow,
    isFirstStep,
    isLastStep,
    goToStep,
  };
}
