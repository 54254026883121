import AnTagOutlineIcon from '@components/AnIcon/icons/AnTagOutlineIcon';
import AnTruckOutlineIcon from '@components/AnIcon/icons/AnTruckOutlineIcon';
import React, { useEffect, useMemo } from 'react';
import AnChevronLeftIcon from '@components/AnIcon/icons/AnChevronLeftIcon';
import AnMailSendOutlineIcon from '@components/AnIcon/icons/AnMailSendOutlineIcon';
import { useSelector } from 'react-redux';
import { selectCart, selectNavigationHistory, selectPurchaseDetails } from '@store/checkoutSlice';
import { isCustomBookPurchase } from './types';
import useCheckoutNavigation from './hooks/useCheckoutNavigation';

function CheckoutQuickSummary() {
  const cart = useSelector(selectCart);
  const purchaseDetails = useSelector(selectPurchaseDetails);
  const { isFirstStep, handleBack } = useCheckoutNavigation();
  const navigationHistory = useSelector(selectNavigationHistory);

  useEffect(() => {
    console.log('navigationHistory', navigationHistory);
  }, [navigationHistory]);

  const totalPrice = useMemo(() => {
    if (cart.items.length === 0 || cart.total === 0) {
      return 'Loading...';
    }
    return `$${cart.total.toFixed(2)}`;
  }, [cart]);

  const isCustomBookPurchaseCheck = useMemo((): boolean => {
    if (!purchaseDetails) {
      return false;
    }
    return isCustomBookPurchase(purchaseDetails);
  }, [purchaseDetails]);

  const shippingString = useMemo(() => {
    if (!purchaseDetails) {
      return 'Today';
    }
    if (isCustomBookPurchaseCheck) {
      return '2-4 weeks';
    }
    if ('deliveryDate' in purchaseDetails && purchaseDetails.deliveryDate) {
      const today = new Date();
      const deliveryDate = new Date(purchaseDetails.deliveryDate);
      if (
        deliveryDate.getDate() === today.getDate()
        && deliveryDate.getMonth() === today.getMonth()
        && deliveryDate.getFullYear() === today.getFullYear()
      ) {
        return 'Today';
      }
      return deliveryDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
    }
    return 'Calculating...';
  }, [purchaseDetails, isCustomBookPurchaseCheck]);

  // const preQuestionSummary = useMemo(() => {
  //   if (giftCardFlow) {
  //     if (giftCardFlow.buyingFor === 'me') {
  //       return 'Buying for me and my child';
  //     // } if (giftCardFlow.buyingFor === 'someoneElse' && giftCardFlow.whoWillCreate === 'me') {
  //     //   return 'Buying a gift, I will create the book';
  //     // } if (giftCardFlow.buyingFor === 'someoneElse' && giftCardFlow.whoWillCreate !== 'me') {
  //     //   return 'Buying a gift, they will create the book';
  //     // } if (giftCardFlow.buyingFor === 'someoneElse' && giftCardFlow.whoWillCreate === '') {
  //     //   return 'Buying a gift';
  //     } if (giftCardFlow.buyingFor === 'someoneElse') {
  //       return 'Buying a gift';
  //     }
  //   }
  //   return '';
  // }, [giftCardFlow]);

  return (
    <div className="w-full relative">
      <div className="flex justify-between items-center mb-2">
        <span className="flex items-center">
          <div className="mr-2">
            <AnTagOutlineIcon size="lg" fillColorClass="fill-primary" />
          </div>
          Total:
          {' '}
          {totalPrice}
        </span>
        <span className="flex items-center text-gray-600">
          <div className="mr-2">
            {isCustomBookPurchaseCheck ? <AnTruckOutlineIcon size="lg" fillColorClass="fill-primary" /> : <AnMailSendOutlineIcon size="lg" fillColorClass="fill-primary" />}
          </div>
          {shippingString}
        </span>
      </div>
      <hr className="border-t border-gray-200 mt-2" />
      {!isFirstStep && (
        <div className="flex items-center mt-2">
          <button onClick={handleBack} className="flex items-center cursor-pointer space-x-1">
            <AnChevronLeftIcon size="sm" strokeColorClass="stroke-gray-500" fillColorClass="fill-gray-500" />
            <p className="text-gray-500">Back</p>
          </button>
        </div>
      )}
      <div className="absolute -bottom-8 left-0 right-0 h-8 bg-gradient-to-t from-transparent to-white pointer-events-none" />
    </div>
  );
}

export default CheckoutQuickSummary;
