import React from 'react';
import AnButton from '@components/AnButton';
import { ValuePropContent } from '../types';

interface ValuePropStepProps {
  content: ValuePropContent;
  onContinue: () => void;
}

export default function ValuePropStep({ content, onContinue }: ValuePropStepProps) {
  return (
    <div className="flex flex-col space-y-6">
      <div className="max-w-lg mx-auto text-center">
        {content.title && <h4 className="mb-4">{content.title}</h4>}
        <p className="text-gray-600 mb-6 text-xs">{content.subheader}</p>

        <div className="mb-8 space-y-8">
          {/* Value Props */}
          {'valueProps' in content && content.valueProps && (
            <ul className="space-y-4 text-left">
              {content.valueProps.map((prop) => (
                <li key={prop.text} className="flex items-center">
                  <span className="mr-2 text-sm">{prop.icon}</span>
                  <span className="text-sm">{prop.text}</span>
                </li>
              ))}
            </ul>
          )}

          {/* Image */}
          {'imageSrc' in content && content.imageSrc && (
            <img
              src={content.imageSrc}
              alt={content.title?.toString() || ''}
              className="rounded-lg shadow-lg mx-auto"
            />
          )}
        </div>

        {/* User Quote */}
        {content.userQuote && (
          <div className="bg-gray-50 p-6 rounded-lg mb-8">
            <p className="italic text-gray-700 mb-2 text-sm">
              &quot;
              {content.userQuote.text}
              &quot;
            </p>
            <p className="text-xs text-gray-500">
              -
              {' '}
              {content.userQuote.author}
            </p>
          </div>
        )}

        <AnButton onClick={onContinue} className="w-full">
          Continue
        </AnButton>
      </div>
    </div>
  );
}
