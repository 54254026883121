import { API } from 'aws-amplify';
import { WANDERLY_NO_AUTH_NAME } from '../constants';
import { MarketingSignupProperties, MarketingSignupResponse } from '../marketingTypes';

interface ApiError extends Error {
  response?: {
    status?: number;
    data?: any;
  };
}

async function submitMarketingSignup(
  signupData: MarketingSignupProperties,
): Promise<MarketingSignupResponse> {
  try {
    const apiName = WANDERLY_NO_AUTH_NAME;
    const path = '/marketingSignup';

    // Get browser and device info
    const browserInfo = {
      userAgent: window.navigator.userAgent,
      language: window.navigator.language,
    };

    // Get URL parameters
    const urlParams = new URLSearchParams(window.location.search);
    const utmParams = {
      utmSource: urlParams.get('utm_source'),
      utmMedium: urlParams.get('utm_medium'),
      utmCampaign: urlParams.get('utm_campaign'),
      utmContent: urlParams.get('utm_content'),
      utmTerm: urlParams.get('utm_term'),
    };

    // Construct enhanced signup data
    const enhancedSignupData = {
      email: signupData.email,
      firstName: signupData.firstName,
      lastName: signupData.lastName,
      source: signupData.source,
      triggerEvent: signupData.triggerEvent,
      ...utmParams,
      browserInfo: JSON.stringify(browserInfo),
      deviceType: /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile/.test(window.navigator.userAgent)
        ? 'mobile'
        : 'desktop',
      landingPage: document.referrer || undefined,
      signupPage: window.location.pathname,
      timestamp: new Date().toISOString(),
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      ...signupData.customProperties,
      ...(signupData.discountInfo && Object.keys(signupData.discountInfo).length > 0
        ? { discountInfo: signupData.discountInfo }
        : {}),
    };

    const response = await API.post(apiName, path, {
      body: enhancedSignupData,
    });

    return response;
  } catch (error) {
    console.error('Error submitting marketing signup:', error);

    // Handle 409 Conflict specifically
    const apiError = error as ApiError;
    if (apiError.response?.status === 409) {
      return {
        success: false,
        message: apiError.response.data.message,
        code: apiError.response.data.code,
      };
    }

    throw error;
  }
}

export default submitMarketingSignup;
