import yellowOrganicClosedLine from '@assets/yellow_organic_closed_line.svg';
import yellowOrganicBlob from '@assets/yellow_organic_blob.svg';
import CheckoutHeader from '@features/Checkout/CheckoutHeader';
import { imageBaseURL } from '@lib/imageUtils';
import AnFAQSection from '@components/AnFAQSection';
import {
  lazy, useEffect, useState, Suspense,
} from 'react';
import { Loader } from '@aws-amplify/ui-react';
import colors from 'src/colors';
import { useDispatch, useSelector } from 'react-redux';
import {
  initializeCheckout, selectIsCheckoutInitialized,
  fetchNoAuthMetadata,
} from '@store/checkoutSlice';
import { AppDispatch } from '@store/store';
import { Footer } from '@components/AnStaticPageFooter';
import { SeoMeta } from '@components/SEO';
import { PAGE_META, SECTION_META } from '@components/SEO/DefaultMeta';
// import Cookies from 'js-cookie';
import NoAuthPageWrapper from '@components/NoAuthPageWrapper';
import PolaroidStrip from '@components/AnPolaroidStrip';
import { HeroBanner } from './HeroBanner';
import { TwoPaneSection } from './TwoPaneSection';
import UserQuotesComponent from './UserQuotes';
import BuyNowButton from './BuyNowButton';
import TryTheAppComponent from './TryTheApp';
import HowItWorksComponent from './HowItWorks';
import quotes1 from './data/user-quotes-1';
import quotes2 from './data/user-quotes-2';
import { FoundersNote } from './FoundersNote';
import GALLERY_BOOK_IMAGES from './data/book-carousel';
import GALLERY_IMAGES_APP from './data/app-carousel';
import faqData from './data/landing-page-faqs-map.json';
import PromotionBanner from './PromotionBanner';
// import DiscountPromoModal from './DiscountPromo/DiscountPromoModal';
import DiscountPromoContent from './DiscountPromo/DiscountPromoContent';
import AssurancesBanner from './AssurancesBanner';
// Add lazy loaded ImageCarousel
const ImageCarousel = lazy(() => import('@components/AnImageCarousel'));

// const PROMO_TIMEOUT_MS = 5000;
// const PROMO_REVIEW_DAYS = 7;

function FeatureContent1() {
  return (
    <div className="space-y-6 max-w-xl">
      <h3 className="text-2xl sm:text-3xl lg:text-4xl font-bold text-gray-900">
        Not Just Another Personalized Book —
        <br />
        <span className="text-primary">A Truly Interactive Adventure</span>
      </h3>
      <ul className="space-y-4 text-base sm:text-lg">
        <li className="flex items-start gap-3">
          <span className="text-xl sm:text-2xl lg:text-3xl">⭐</span>
          <span>Your child becomes the hero of their own magical journey</span>
        </li>
        <li className="flex items-start gap-3">
          <span className="text-xl sm:text-2xl lg:text-3xl">🔄</span>
          <span>Choose-your-path storytelling makes every story unique</span>
        </li>
        <li className="flex items-start gap-3">
          <span className="text-xl sm:text-2xl lg:text-3xl">💝</span>
          <span>Include family members, friends, and even magical pets in the story</span>
        </li>
        <li className="flex items-start gap-3">
          <span className="text-xl sm:text-2xl lg:text-3xl">🎨</span>
          <span>Premium hardcover book with stunning custom illustrations</span>
        </li>
      </ul>
      <p className="text-base sm:text-lg">
        Book and subscription packages start at $69, including shipping.
      </p>
      <BuyNowButton path="landing_page" analyticsLabel="feature_content_1" />
    </div>
  );
}

function FeatureImage1() {
  return (
    <div className="bg-white p-6 rounded-lg shadow">
      <Suspense fallback={(
        <div className="flex items-center justify-center h-full">
          <Loader
            filledColor={colors.accent1.DEFAULT}
            width="4em"
            height="4em"
          />
        </div>
      )}
      >
        <ImageCarousel images={GALLERY_BOOK_IMAGES} showThumbnails />
      </Suspense>
    </div>
  );
}

function FeatureContent2() {
  return (
    <div className="space-y-6 max-w-xl">
      <h3 className="text-2xl sm:text-3xl lg:text-4xl font-bold text-gray-900">
        Stories That Come to Life as You Read —
        <br />
        <span className="text-primary">As Unique as Your Child</span>
      </h3>
      <ul className="space-y-4 text-base sm:text-lg">
        <li className="flex items-start gap-3">
          <span className="text-xl sm:text-2xl lg:text-3xl">📚</span>
          <span>4 engaging story genres: Adventure, Help & Animal, Science, and History</span>
        </li>
        <li className="flex items-start gap-3">
          <span className="text-xl sm:text-2xl lg:text-3xl">🎭</span>
          <span>Watch the story unfold based on your child&apos;s choices</span>
        </li>
        <li className="flex items-start gap-3">
          <span className="text-xl sm:text-2xl lg:text-3xl">📈</span>
          <span>4 reading levels designed for ages 3-8, adapting to your child&apos;s growth</span>
        </li>
        <li className="flex items-start gap-3">
          <span className="text-xl sm:text-2xl lg:text-3xl">🦸</span>
          <span>60+ character avatars to choose from, making every story personal</span>
        </li>
        <li className="flex items-start gap-3">
          <span className="text-xl sm:text-2xl lg:text-3xl">✅</span>
          <span>Parent-reviewed illustrations ensuring age-appropriate content</span>
        </li>
      </ul>
      <p className="text-base sm:text-lg">
        Unlimited access to the app is included with every package purchase.
      </p>
      <BuyNowButton path="landing_page" analyticsLabel="feature_content_2" />
    </div>
  );
}

function FeatureImage2() {
  return (
    <div className="bg-white p-6 rounded-lg shadow">
      <Suspense fallback={(
        <div className="flex items-center justify-center h-full">
          <Loader
            filledColor={colors.accent1.DEFAULT}
            width="4em"
            height="4em"
          />
        </div>
      )}
      >
        <ImageCarousel images={GALLERY_IMAGES_APP} showThumbnails />
      </Suspense>
    </div>
  );
}

// function safeGetCookie(key: string) {
//   try {
//     return Cookies.get(key);
//   } catch (e) {
//     console.error('Error accessing cookie:', e);
//     return null;
//   }
// }

function LandingPage() {
  const [isDesktop, setIsDesktop] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const isCheckoutInitialized = useSelector(selectIsCheckoutInitialized);
  // const [showPromoModal, setShowPromoModal] = useState(false);

  // Only initialize checkout when needed
  useEffect(() => {
    if (!isCheckoutInitialized) {
      dispatch(initializeCheckout({ type: 'buy-package' }));
    }
  }, [dispatch, isCheckoutInitialized]);

  // Initialize Landing Page
  useEffect(() => {
    const init = async () => {
      dispatch(fetchNoAuthMetadata());
    };

    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 1024); // Using lg breakpoint (1024px)
    };

    // Initial check
    handleResize();
    init();

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Add new useEffect for modal timing
  // useEffect(() => {
  //   // Check if running in a browser environment
  //   if (typeof window === 'undefined') {
  //     // Return a no-op cleanup function to satisfy ESLint
  //     return () => {};
  //   }

  //   const hasSubmittedPromo = safeGetCookie('hasSubmittedPromo');
  //   const lastPromoDismissed = safeGetCookie('lastPromoDismissed');
  //   const sessionPromoShown = sessionStorage.getItem('promoShownThisSession');

  //   let shouldShowPromo = false;

  //   if (!hasSubmittedPromo && !sessionPromoShown) {
  //     if (lastPromoDismissed) {
  //       const lastDismissDate = new Date(lastPromoDismissed);
  //       const currentDate = new Date();
  //       const daysSinceDismiss = (currentDate.getTime() - lastDismissDate.getTime()) / (1000 * 60 * 60 * 24);

  //       if (daysSinceDismiss >= PROMO_REVIEW_DAYS) {
  //         shouldShowPromo = true;
  //       }
  //     } else {
  //       shouldShowPromo = true;
  //     }
  //   }

  //   let timer: ReturnType<typeof setTimeout> | null = null;

  //   if (shouldShowPromo) {
  //     timer = setTimeout(() => {
  //       setShowPromoModal(true);
  //       sessionStorage.setItem('promoShownThisSession', 'true');
  //     }, PROMO_TIMEOUT_MS);
  //   }

  //   // Cleanup function to clear the timer if it exists
  //   return () => {
  //     if (timer) {
  //       clearTimeout(timer);
  //     }
  //   };
  // }, []);

  // // Handler to close the promo modal and set lastPromoDismissed cookie
  // const handleClosePromoModal = () => {
  //   setShowPromoModal(false);
  //   Cookies.set('lastPromoDismissed', new Date().toISOString(), {
  //     expires: PROMO_REVIEW_DAYS,
  //   });
  // };

  return (
    <NoAuthPageWrapper>
      <SeoMeta
        {...PAGE_META.home}
        canonicalUrl="/"
      />
      <div className="bg-white min-h-screen relative">
        {/* Background container - removed overflow-hidden */}
        <div className="fixed inset-0 pointer-events-none">
          {/* Color Splash Image - using aspect-square to maintain 1:1 ratio */}
          <img
            src={`${imageBaseURL}misc/checkout/wanderly-color-splash-1_1024w.webp`}
            alt=""
            className="fixed left-0 top-1/2 w-[800px] aspect-square opacity-40 transform -translate-x-1/4 -translate-y-1/2"
          />
          {/* Yellow Organic Blob - updated positioning */}
          <img
            src={yellowOrganicBlob}
            alt=""
            className="fixed top-0 left-2/3 w-[800px] h-auto transform -translate-x-1/2 -translate-y-1/4"
          />

          {/* Background SVGs - updated positioning */}
          <img
            src={yellowOrganicClosedLine}
            alt=""
            className="fixed top-0 left-0 w-[400px] h-auto opacity-50 transform -translate-x-1/4 -translate-y-1/4"
          />
          <img
            src={yellowOrganicClosedLine}
            alt=""
            className="fixed bottom-0 right-0 w-[400px] h-auto opacity-50 transform translate-x-1/4 translate-y-1/4 rotate-180"
          />
        </div>

        <PromotionBanner />

        <CheckoutHeader showLogo="" />

        <main className="relative z-10">
          {/* Hero Section */}
          <section>
            <HeroBanner isDesktop={isDesktop} />
          </section>

          <section className="bg-white mb-8">
            <AssurancesBanner isDesktop={isDesktop} />
          </section>

          {/* User Quotes Section */}
          <section className="py-4">
            <PolaroidStrip isDesktop={isDesktop} />
          </section>

          <section id="try-the-app" aria-label={SECTION_META.tryTheApp.title} className="py-4 bg-white">
            <TryTheAppComponent isDesktop={isDesktop} />
          </section>

          <section className="py-4">
            <UserQuotesComponent quotes={quotes1} isDesktop={isDesktop} />
          </section>

          <section id="features" aria-label={SECTION_META.features.title} className="py-4 bg-white">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <TwoPaneSection
                leftContent={<FeatureContent1 />}
                rightContent={<FeatureImage1 />}
                isDesktop={isDesktop}
              />
            </div>
          </section>

          <section id="how-it-works" aria-label={SECTION_META.howItWorks.title} className="py-4 bg-white">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <HowItWorksComponent />
            </div>
          </section>

          <section id="features" aria-label={SECTION_META.features.title} className="py-4 bg-white">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <TwoPaneSection
                leftContent={<FeatureImage2 />}
                rightContent={<FeatureContent2 />}
                isDesktop={isDesktop}
              />
            </div>
          </section>

          {/* User Quotes Section */}
          <section className="py-4">
            <UserQuotesComponent quotes={quotes2} isDesktop={isDesktop} />
          </section>

          <FoundersNote isDesktop={isDesktop} />

          <section id="faq" aria-label={SECTION_META.faq.title} className="py-12 bg-white">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <AnFAQSection data={faqData} />
            </div>
          </section>

          <section className="py-12 bg-white">
            <div className="max-w-md mx-auto px-6 sm:px-8 md:px-10">
              <div className="p-6 sm:p-8">
                <DiscountPromoContent className="max-w-sm mx-auto" />
              </div>
            </div>
          </section>
        </main>

        <Footer />
      </div>
      {/* <DiscountPromoModal
        isOpen={showPromoModal}
        onClose={handleClosePromoModal}
      /> */}
    </NoAuthPageWrapper>
  );
}

export default LandingPage;
