import React, { useEffect, useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import AnTextField from '@components/AnTextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { styled } from '@mui/material/styles';
import isEmail from 'validator/lib/isEmail';
import { useDispatch, useSelector } from 'react-redux';
import { selectPurchaseDetails, setPackagePurchaseDetails } from '@store/checkoutSlice';
import { zonedTimeToUtc } from 'date-fns-tz';
import AnButton from '@components/AnButton';
import { PackagePurchase } from '../types';
import useCheckoutNavigation from '../hooks/useCheckoutNavigation';

interface GiftDeliveryInfoProps {
  onIsFormValid: (isValid: boolean) => void;
}

// Define the validation schema
const validationSchema = yup.object().shape({
  recipientFirstName: yup.string().required('Please enter recipient first name'),
  recipientLastName: yup.string().required('Please enter recipient last name'),
  recipientEmail: yup
    .string()
    .test('is-valid-email', 'Please enter a valid email address', (value) => isEmail(value || ''))
    .required('Please enter recipient email'),
  deliveryDate: yup.date().nullable(),
});

const StyledDatePicker = styled(DatePicker)(() => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '0.5rem !important',
    '& fieldset': {
      borderColor: 'border-primary !important',
      borderRadius: '0.5rem !important',
    },
    '&:hover fieldset': {
      borderColor: 'border-primary !important',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'border-primary !important',
      borderWidth: '1px !important',
    },
    // Add specific styling for the input when focused
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderRadius: '0.5rem !important',
    },
    // Ensure the input maintains rounded corners in all states
    '& .MuiOutlinedInput-input': {
      borderRadius: '0.5rem !important',
    },
  },
  '& .MuiInputBase-input': {
    padding: '0.375rem 1rem !important',
    textAlign: 'center !important',
    borderRadius: '0.5rem !important',
  },
  // Ensure consistent border radius for all parts
  '& .MuiOutlinedInput-notchedOutline': {
    borderRadius: '0.5rem !important',
  },
  '& .MuiInputBase-root': {
    borderRadius: '0.5rem !important',
  },
  // Add specific styling for the popup paper
  '& .MuiPaper-root': {
    borderRadius: '0.5rem !important',
  },
  // Ensure the input wrapper maintains rounded corners
  '& .MuiInputBase-formControl': {
    borderRadius: '0.5rem !important',
  },
}));

export default function GiftDeliveryInfo({
  onIsFormValid,
}: GiftDeliveryInfoProps) {
  const dispatch = useDispatch();

  const purchaseDetails = useSelector(selectPurchaseDetails) as PackagePurchase;

  const prevRecipientFirstNameRef = useRef(purchaseDetails?.recipientFirstName);
  const prevRecipientLastNameRef = useRef(purchaseDetails?.recipientLastName);
  const prevRecipientEmailRef = useRef(purchaseDetails?.recipientEmail);
  const prevDeliveryDateRef = useRef(purchaseDetails?.deliveryDate);

  const { navigateToNext } = useCheckoutNavigation();

  const {
    control,
    formState: { errors, isValid },
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    defaultValues: {
      recipientFirstName: purchaseDetails?.recipientFirstName || '',
      recipientLastName: purchaseDetails?.recipientLastName || '',
      recipientEmail: purchaseDetails?.recipientEmail || '',
      deliveryDate: purchaseDetails?.deliveryDate ? new Date(purchaseDetails.deliveryDate) : null,
    },
  });

  const watchedRecipientFirstName = watch('recipientFirstName');
  const watchedRecipientLastName = watch('recipientLastName');
  const watchedRecipientEmail = watch('recipientEmail');
  const watchedDeliveryDate = watch('deliveryDate');

  useEffect(() => {
    onIsFormValid(isValid);
  }, [isValid, onIsFormValid]);

  useEffect(() => {
    if (watchedRecipientFirstName !== prevRecipientFirstNameRef.current
        || watchedRecipientLastName !== prevRecipientLastNameRef.current
        || watchedRecipientEmail !== prevRecipientEmailRef.current
        || watchedDeliveryDate !== prevDeliveryDateRef.current) {
      dispatch(setPackagePurchaseDetails({
        recipientFirstName: watchedRecipientFirstName,
        recipientLastName: watchedRecipientLastName,
        recipientEmail: watchedRecipientEmail,
        deliveryDate: watchedDeliveryDate,
      }));
      prevRecipientFirstNameRef.current = watchedRecipientFirstName;
      prevRecipientLastNameRef.current = watchedRecipientLastName;
      prevRecipientEmailRef.current = watchedRecipientEmail;
      prevDeliveryDateRef.current = watchedDeliveryDate ?? null;
    }
  }, [watchedRecipientFirstName, watchedRecipientLastName, watchedRecipientEmail, watchedDeliveryDate, dispatch]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <form className="my-4 max-w-sm">
        <div className="mb-4">
          <p className="text-sm text-gray-600 pb-1">Recipient First Name</p>
          <AnTextField
            name="recipientFirstName"
            placeholder="First Name"
            control={control}
            error={!!errors.recipientFirstName}
            errorMessage={errors.recipientFirstName?.message}
            textFieldStyle="fully-enclosed"
            autoComplete="off"
          />
        </div>
        <div className="mb-4">
          <p className="text-sm text-gray-600 pb-1">Recipient Last Name</p>
          <AnTextField
            name="recipientLastName"
            placeholder="Last Name"
            control={control}
            error={!!errors.recipientLastName}
            errorMessage={errors.recipientLastName?.message}
            textFieldStyle="fully-enclosed"
            autoComplete="off"
          />
        </div>
        <div className="mb-4">
          <p className="text-sm text-gray-600 pb-1">Recipient Email</p>
          <AnTextField
            name="recipientEmail"
            placeholder="first.last@example.com"
            control={control}
            error={!!errors.recipientEmail}
            errorMessage={errors.recipientEmail?.message}
            textFieldStyle="fully-enclosed"
            autoComplete="off"
          />
        </div>
        <div className="mb-4">
          <p className="text-sm text-gray-600 pb-1">Delivery Date</p>
          <Controller
            name="deliveryDate"
            control={control}
            render={({ field }) => (
              <StyledDatePicker
                {...field}
                minDate={new Date()}
                maxDate={new Date(Date.now() + 6 * 30 * 24 * 60 * 60 * 1000)}
                onChange={(date: Date | null) => {
                  if (date) {
                    const today = new Date();
                    const isToday = date.toDateString() === today.toDateString();

                    if (!isToday) {
                      // Create a new date at 5am ET, properly handling DST
                      const year = date.getFullYear();
                      const month = date.getMonth();
                      const day = date.getDate();
                      const finalDate = zonedTimeToUtc(`${year}-${String(month + 1).padStart(2, '0')}-${String(day).padStart(2, '0')} 05:00`, 'America/New_York');
                      setValue('deliveryDate', finalDate);
                    } else {
                      setValue('deliveryDate', today);
                    }
                  } else {
                    setValue('deliveryDate', null);
                  }
                }}
                slotProps={{
                  textField: {
                    placeholder: 'Select Date',
                    error: !!errors.deliveryDate,
                    helperText: errors.deliveryDate?.message,
                  },
                }}
              />
            )}
          />
        </div>
      </form>
      <div className="mt-4">
        <AnButton
          onClick={navigateToNext}
          disabled={!isValid}
        >
          Next
        </AnButton>
      </div>
    </LocalizationProvider>
  );
}
