import { Storage } from 'aws-amplify';

export default async function getNoAuthMetadata() {
  const defaultMetadata = {
    emailListDiscount: {
      percent: 10,
      couponCode: 'NEWADVENTURE10',
    },
  };

  try {
    const result = await Storage.get('app-metadata-no-auth.json', {
      download: true,
      level: 'public',
      cacheControl: 'max-age=3600',
    });

    if (result.Body) {
      const text = await (result.Body as Blob).text();
      const fetchedData = JSON.parse(text);
      // Merge the fetched data with default values, allowing overrides
      return {
        ...defaultMetadata,
        ...fetchedData,
        // Deep merge for nested emailListDiscount object
        emailListDiscount: {
          ...defaultMetadata.emailListDiscount,
          ...fetchedData.emailListDiscount,
        },
      };
    }
    return defaultMetadata;
  } catch (error) {
    console.error('Error fetching metadata:', error);
    return defaultMetadata;
  }
}
