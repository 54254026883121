import React from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@store/store';
import { navigateToNextStep } from '@store/checkoutSlice';
import ValuePropStep from './ValuePropStep';
import { REASON_VALUE_PROPS } from './valuePropContent';
import { WanderlyReason } from '../types';

interface ReasonValuePropProps {
  reason: WanderlyReason;
}

export default function ReasonValueProp({ reason }: ReasonValuePropProps) {
  const dispatch = useDispatch<AppDispatch>();

  const handleContinue = () => {
    dispatch(navigateToNextStep());
  };

  return (
    <ValuePropStep
      content={REASON_VALUE_PROPS[reason]}
      onContinue={handleContinue}
    />
  );
}
